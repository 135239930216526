
<div class="w-full p-1 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-50">

    <div>
        <a (click)="selAucune()"
        [class]="aucune ? 'block w-full px-4 py-2 text-gray-50 bg-blue-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600'
            : 'block w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-gray-50 dark:focus:ring-gray-500 dark:focus:text-gray-50'">
        Adhérent sans adhésion
        </a>
    </div>

    <div *ngFor="let act of listeActivites; index as index" class="">
        <a (click)="selectAct(act)"
        [class]="isSelected(act) ? 'block w-full px-4 py-2 text-gray-50 bg-blue-700 border-b border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600'
            : 'block w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-gray-50 dark:focus:ring-gray-500 dark:focus:text-gray-50'">
        {{act.attributes.nom}}
        </a>
    </div>
    <!-- <a href="#" class="block w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-gray-50 dark:focus:ring-gray-500 dark:focus:text-gray-50">
        Settings
    </a>
    <a href="#" class="block w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-gray-50 dark:focus:ring-gray-500 dark:focus:text-gray-50">
        Messages
    </a> -->


</div>
