import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Composant } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    

  constructor() { }

  ngOnInit(): void {

  }

}
