import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/private/auth/authentication.service';
import { ApiService } from 'src/app/services/api.service';
import { ActiviteName } from 'src/app/services/models';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

    role: string = '';
    isLogged = false;
    activites: ActiviteName[] = [];

    adresse: string = '';
    horaires: string = '';
    tel: string = '';
    telValue: string = '';
    telNPValue: string = '';
    telNP: string = '';
    email: string = '';

  constructor(private authService: AuthenticationService, private service: ApiService) { }

  ngOnInit(): void {
    this.authService.role.subscribe(res => this.role = res);
    //this.isLogged = this.authService.isLoggedIn();
    this.authService.isLogged.subscribe(log => this.isLogged = log);
    if(this.authService.isLoggedIn) {
        this.authService.isLogged.next(true);
    } else {
        this.authService.isLogged.next(false);
    }
    this.service.getActivitesName().subscribe({
        next: act => this.activites = act.data,
        error: err => console.log(err)
    });
    this.service.getParam().subscribe({
        next: res => {
            this.adresse = this.service.getParamValue('adresse', res.data, true);
            this.horaires = this.service.getParamValue('horaires', res.data, true);
            this.email =this.service.getParamValue('email', res.data, true);
            this.tel = this.service.getParamValue('telephone', res.data, true, true);
            this.telNP = this.service.getParamValue('Tel-Narbonne-Plage', res.data, true, true);

            this.telValue = this.service.getParamValue('telephone', res.data)
                            .replace('<BR>','')
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('.', '')
                            .replace('/', '');
            this.telNPValue = this.service.getParamValue('Tel-Narbonne-Plage', res.data)
                            .replace('<BR>','')
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('.', '')
                            .replace('/', '');
        }
    });

  }

  logout(url: string) {
    this.authService.logout(url);
  }


}
