import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Composant } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-narbonne-plage',
  templateUrl: './narbonne-plage.component.html',
  styleUrls: ['./narbonne-plage.component.scss']
})
export class NarbonnePlageComponent implements OnInit {
    aPropos: Composant;
    aProposContent: string;

    constructor(private service: ApiService, private utils: Utils) { }

    ngOnInit(): void {
        this.service.getComposants('presentation-narbonne-plage', 'a propos').subscribe({
            next: res => {
                this.aPropos = res.data[0];
                this.aProposContent = this.utils.sanitizeHTML(res.data[0].attributes.contenu);
            }
        });

    }

}
