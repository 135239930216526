import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { Formation } from 'src/app/services/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {

    listFormations: Observable<Formation[]>;

    formSelected: Formation;

  constructor(private service: ApiService) { }

  get url() {
    return environment.url;
  }

  ngOnInit(): void {
    this.listFormations = this.service.getFormations().pipe(map(res => res.data));
    this.listFormations.subscribe();
  }

}
