<app-banner [title]="'Inscription'"
            [breadcrumb]="[['Accueil', 'index.html'], ['connexion', 'login']]"
            [bckUrl]="'login'"
></app-banner>
<div class="pt-50">&nbsp;</div>
<div class="login-area ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <i class="far fa-user text-center"></i>
                <h3>Inscription</h3>
            </div>

            <a href="https://www2.cnnarbonne.fr/uploads/Dossier_inscription_8d7b57c26b.pdf">Télécharger le bulletin</a>

            <!-- <div class="form-sign">
                <form [formGroup]="inscriptionForm" (ngSubmit)="onSubmit()" class="space-y-4">
                    <div>
                    <label for="nom" class="block text-sm font-medium">Nom</label>
                    <input id="nom" formControlName="nom" type="text" class="mt-1 block w-full" required>
                    <div *ngIf="inscriptionForm.get('nom').invalid && inscriptionForm.get('nom').touched" class="text-red-500 text-xs">
                        Le nom est requis.
                    </div>
                    </div>

                    <div>
                    <label for="prenom" class="block text-sm font-medium">Prénom</label>
                    <input id="prenom" formControlName="prenom" type="text" class="mt-1 block w-full" required>
                    <div *ngIf="inscriptionForm.get('prenom').invalid && inscriptionForm.get('prenom').touched" class="text-red-500 text-xs">
                        Le prénom est requis.
                    </div>
                    </div>

                    <div>
                    <label for="dateNaissance" class="block text-sm font-medium">Date de naissance</label>
                    <input id="dateNaissance" formControlName="dateNaissance" type="date" class="mt-1 block w-full" required>
                    <div *ngIf="inscriptionForm.get('dateNaissance').invalid && inscriptionForm.get('dateNaissance').touched" class="text-red-500 text-xs">
                        La date de naissance est requise.
                    </div>
                    </div>

                    <div>
                    <label for="sexe" class="block text-sm font-medium">Sexe</label>
                    <select id="sexe" formControlName="sexe" class="mt-1 block w-full">
                        <option value="Masculin">Masculin</option>
                        <option value="Féminin">Féminin</option>
                    </select>
                    <div *ngIf="inscriptionForm.get('sexe').invalid && inscriptionForm.get('sexe').touched" class="text-red-500 text-xs">
                        Le sexe est requis.
                    </div>
                    </div>

                    <div>
                    <label for="adresse" class="block text-sm font-medium">Adresse</label>
                    <input id="adresse" formControlName="adresse" type="text" class="mt-1 block w-full" required>
                    <div *ngIf="inscriptionForm.get('adresse').invalid && inscriptionForm.get('adresse').touched" class="text-red-500 text-xs">
                        L'adresse est requise.
                    </div>
                    </div>

                    <div>
                    <label for="telephone" class="block text-sm font-medium">Téléphone</label>
                    <input id="telephone" formControlName="telephone" type="text" class="mt-1 block w-full" required>
                    <div *ngIf="inscriptionForm.get('telephone').invalid && inscriptionForm.get('telephone').touched" class="text-red-500 text-xs">
                        Téléphone invalide. Il doit contenir 10 chiffres.
                    </div>
                    </div>

                    <div>
                    <label for="email" class="block text-sm font-medium">Email</label>
                    <input id="email" formControlName="email" type="email" class="mt-1 block w-full" required>
                    <div *ngIf="inscriptionForm.get('email').invalid && inscriptionForm.get('email').touched" class="text-red-500 text-xs">
                        L'email est invalide.
                    </div>
                    </div>




                    <div class="space-y-4">
                        <label class="block text-sm font-medium">Activités</label>
                        <div *ngFor="let data of activites">
                            <input type="checkbox" (change)="onChange(data.activite, $event.target.checked)"> {{data.activite}}<br>
                        </div>

                      </div>

                        <div class="space-y-4">
                            <label class="block text-sm font-medium">Options de Paiement</label>
                            <div class="flex items-center">
                            <input id="cheque" formControlName="cheque" type="checkbox" class="mr-2">
                            <label for="cheque">Chèque</label>
                            </div>
                            <div class="flex items-center">
                            <input id="espece" formControlName="espece" type="checkbox" class="mr-2">
                            <label for="espece">Espèces</label>
                            </div>
                            <div class="flex items-center">
                            <input id="chequesVacances" formControlName="chequesVacances" type="checkbox" class="mr-2">
                            <label for="chequesVacances">Chèques Vacances</label>
                            </div>
                        </div>

                    <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded" >Générer PDF</button>
                </form>
            </div> -->
        </div>
    </div>
</div>

