import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Activite, Composant } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-activites',
  templateUrl: './activites.component.html',
  styleUrls: ['./activites.component.scss']
})
export class ActivitesComponent implements OnInit, OnChanges {

    activites: Array<Activite>;
    prestations: Composant;
    @Input() inscription = false;
    @Input() lieu: string = null;
    @Input() idSuppr: number = null;
    @Input() idAdherent: number = null;
    @Output() emitId = new EventEmitter();

  constructor(private service: ApiService, private utils: Utils) { }

  ngOnInit(): void {
    this.initActivites();
    this.service.getComposants('accueil', 'prestations').subscribe({
        next: resComp => this.prestations = resComp.data[0]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.idSuppr) {
      if(changes.idSuppr.currentValue !== changes.idSuppr.previousValue) {
        this.initActivites();
      }
    }
  }

  initActivites() {
    this.service.getActivites(this.lieu, this.idSuppr).subscribe({
        next: res => this.activites = res.data,
        error: err => console.log(err)
    });
  }

  emit(id) {
    this.emitId.emit(id);
  }


  sanitize(str) {
    return this.utils.sanitizeHTML(str);
  }

}
