import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Confirmation } from './models';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  message$: BehaviorSubject<Confirmation> = new BehaviorSubject(null);
  afficheConfirm = false;
  returnedValue$: BehaviorSubject<number> = new BehaviorSubject(-1);

  constructor() {
    this.message$.next({
      mode: 'success',
      titre:'Successfully saved!',
      texte: 'Anyone with a link can now view this file.',
      btnAnnule: 'Annuler',
      btnValide: 'OK',
      value: 0
    });
    this.returnedValue$.next(-1);
  }

  setMessage(msg: Confirmation) {
    this.message$.next({
      mode: msg.mode,
      titre: msg.titre,
      texte: msg.texte,
      btnAnnule: msg.btnAnnule ? msg.btnAnnule : 'Annuler',
      btnValide: msg.btnValide ? msg.btnValide : 'OK',
      value: msg.value
    });
    this.afficheConfirm = true;
  }

  setValue(val: number) {
    this.returnedValue$.next(val);
  }

  open() {
    this.afficheConfirm = true;
  }
  close() {
    this.afficheConfirm = false;
  }
  annule() {
    this.setValue(0);
  }
  valide() {
    this.setValue(1);
  }
}
