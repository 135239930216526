<!--<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner bg-shape2 bg-color2 img-bck">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Contactez-nous</h3>
                    <ul>
                        <li><a [routerLink]="''">Accueil</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Contactez-nous</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>-->
<app-banner [title]="'Contactez-nous'"
            [breadcrumb]="[['Accueil', 'index.html'], ['Contactez-nous', 'contact']]"
            [bckUrl]="'contact'"
></app-banner>

<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="contact-title text-center mb-50">
            <h2>Vous pouvez nous joindre par t&eacute;l&eacute;phone pendant les horaires d'ouverture.</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-item">
                    <div class="icon-contact text-center"><i class="far fa-clock"></i></div>
                    <h3 [innerHTML]="horaires"></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-item">
                    <div class="icon-contact text-center"><i class="fas fa-map-marker-alt"></i></div>
                    <h3 [innerHTML]="adresse"></h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-item text-center">
                    <div class="icon-contact"><i class="flaticon-telephone"></i></div>
                    Narbonne : <h3><a [href]="'tel:'+telValue"  [innerHTML]="tel"></a></h3><br />
                    Narbonne-Plage : <h3><a [href]="'tel:'+telNPValue"  [innerHTML]="telNP"></a></h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="contact-img">
                    <img src="assets/img/contact/nageur-dos-crawl.webp" alt="Contact Images">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-area">
                    <div class="form-section">
                        <h2>Comment pouvons-nous vous aider ?</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Votre Nom">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control " required placeholder="Votre eMail">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Votre numéro de téléphone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Objet">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-message textarea-hight" id="message" cols="30" rows="5" required placeholder="Votre message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn1 btn-two">Envoyer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
