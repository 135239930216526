<app-banner [title]="'Nos Formations'"
            [breadcrumb]="[['Accueil', 'index.html'], ['Formations', 'courses']]"
            [bckUrl]="'courses'"
></app-banner>
<div class="container text-center mt-4 mb-8 flex justify-center">
    <img class="w-96 rounded" src="assets/img/course/Logo-Vierge-FNMNS.webp" />
</div>


<div class="container mb-8 mt-4" >
    <a class="cursor-pointer " [routerLink]="'/courses'">
        <div class="scetion-title mb-50 flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
            </svg>

            <div class="pl-4">Retour</div>
        </div>
    </a>
</div>
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-white" >
    <div aria-hidden="true" class="relative">
      <!-- <img [src]="imgServ.imageUrl(activite?.attributes?.photo, '1000x385')" alt="" class="h-96 w-full object-cover object-center"> -->
      <div class="absolute inset-0 bg-gradient-to-t from-white"></div>
    </div>

    <div class="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
      <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{formation?.attributes?.nom}}</h2>
        <p class="mt-4 text-gray-500" [innerHTML]="formationContent"></p>
      </div>


    </div>
</div>

<!-- <section class="trainers-area pt-100 pb-70">
    <app-trainers></app-trainers>
</section> -->
