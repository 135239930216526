

<div class="inner-banner bg-shape1 bg-color2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Cart</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Cart</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-wraps">
                <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Name</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td class="product-thumbnail">
                                    <a routerLink="/products-details"><img src="assets/img/product/cart1.jpg" alt="Image"></a>
                                </td>

                                <td class="product-name">
                                    <a routerLink="/products-details">Diving Glass</a>
                                </td>

                                <td class="product-price">
                                    <span class="unit-amount">$35.00</span>
                                </td>

                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                    </div>
                                </td>

                                <td class="product-subtotal">
                                    <span class="subtotal-amount">$35.00</span>
                                    <a routerLink="/cart" class="remove"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>

                            <tr>
                                <td class="product-thumbnail">
                                    <a routerLink="/products-details"><img src="assets/img/product/cart2.jpg" alt="Image"></a>
                                </td>

                                <td class="product-name">
                                    <a routerLink="/products-details">Diving Rite</a>
                                </td>

                                <td class="product-price">
                                    <span class="unit-amount">$30.00</span>
                                </td>

                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                    </div>
                                </td>

                                <td class="product-subtotal">
                                    <span class="subtotal-amount">$30.00</span>
                                    <a routerLink="/cart" class="remove"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>

                            <tr>
                                <td class="product-thumbnail">
                                    <a routerLink="/products-details"><img src="assets/img/product/cart3.jpg" alt="Image"></a>
                                </td>

                                <td class="product-name">
                                    <a routerLink="/products-details">Fins Bungee</a>
                                </td>

                                <td class="product-price">
                                    <span class="unit-amount"> $75.00</span>
                                </td>

                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                    </div>
                                </td>

                                <td class="product-subtotal">
                                    <span class="subtotal-amount">$75.00</span>
                                    <a routerLink="/cart" class="remove"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>

                            <tr>
                                <td class="product-thumbnail">
                                    <a routerLink="/products-details"><img src="assets/img/product/cart4.jpg" alt="Image"></a>
                                </td>

                                <td class="product-name">
                                    <a routerLink="/products-details">Life Jacket</a>
                                </td>

                                <td class="product-price">
                                    <span class="unit-amount">$25.00</span>
                                </td>

                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"><i class="fas fa-minus"></i></span>
                                        <input type="text" value="1">
                                        <span class="plus-btn"><i class="fas fa-plus"></i></span>
                                    </div>
                                </td>

                                <td class="product-subtotal">
                                    <span class="subtotal-amount">$25.00</span>
                                    <a routerLink="/cart" class="remove"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="cart-buttons">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-sm-7 col-md-7">
                            <div class="continue-shopping-box">
                                <a routerLink="/shop" class="default-btn1">Continue Shopping</a>
                            </div>
                        </div>

                        <div class="col-lg-5 col-sm-5 col-md-5 text-end">
                            <a routerLink="/cart" class=" default-btn1">Update Cart</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="cart-calc">
                        <div class="shops-form">
                            <h3>Calculate Shipping</h3>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select>
                                            <option>Credit Card Type</option>
                                            <option>Another option</option>
                                            <option>A option</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <input type="text" class="form-control" placeholder="Credit Card Number">
                                </div>

                                <div class="form-group col-12">
                                    <input type="text" class="form-control" placeholder="Card Verification Number">
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Coupon Code">
                            </div>

                            <a href="#" class="default-btn1">Apply Coupon</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="cart-totals">
                        <h3>Cart Totals</h3>
                        <ul>
                            <li>Subtotal <span>$150.00</span></li>
                            <li>Shipping <span>$30.00</span></li>
                            <li>Coupon <span>$20.00</span></li>
                            <li>Total <span><b>$160.00</b></span></li>
                        </ul>
                        <a routerLink="/checkout" class="default-btn1">Proceed To Checkout</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
