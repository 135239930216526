import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Formation } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.scss']
})
export class DetailCourseComponent {

    formation: Formation;
    formationContent: string = '';
    id: number;

    constructor(
        private service: ApiService,
        private route: ActivatedRoute,
        private utils: Utils,
    ) { }

    getFormation() {
        if(!this.id)
            this.id = parseInt(this.route.snapshot.paramMap.get('id'));

        this.service.getFormationById(this.id).subscribe({
            next: res => {
                this.formation = res.data;
                this.formationContent = this.utils.sanitizeHTML(res.data.attributes.description);
            }
        });

    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.getFormation();
        });
    }

}
