

<div class="inner-banner bg-shape3 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>FAQ</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-section pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>FAQ</span>
            <h2>Ask Question</h2>
        </div>

        <div class="faq-area">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class="fas fa-chevron-circle-right"></i> How Can a Beginner Start Swimming?</a>
                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fas fa-chevron-circle-right"></i> What Are the Steps to Start Swimming?</a>
                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fas fa-chevron-circle-right"></i> What Are the Steps to Learn Child Swimming?</a>
                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fas fa-chevron-circle-right"></i> What Are the Best Practices for Swimming?</a>
                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fas fa-chevron-circle-right"></i> What Kind of Equipment is Needed for Swimming?</a>
                    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </li>
            </ul>
        </div>
    </div>
</section>
