import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {

  constructor() {}

  generatePdf(formValues: any) {
    const documentDefinition = this.getDocumentDefinition(formValues);
    pdfMake.createPdf(documentDefinition).download('fiche-inscription.pdf');
  }

  getDocumentDefinition(formValues: any) {
    
    return {
      content: [
        // Titre principal
        { text: 'BULLETIN D\'ADHESION 2023 - 2024', style: 'header' },
        { text: 'Cercle des Nageurs Narbonnais', style: 'subheader' },

        // Activités proposées
        { text: 'Activités', style: 'sectionHeader' },
        {
          columns: [
            {
              ul: formValues.activites
            }
          ]
        },

        // Informations personnelles
        { text: 'Informations Personnelles', style: 'sectionHeader' },
        {
          columns: [
            { text: `Nom: ${formValues.nom}`, bold: true },
            { text: `Prénom: ${formValues.prenom}`, bold: true }
          ]
        },
        { text: `Date de naissance: ${formValues.dateNaissance}` },
        { text: `Sexe: ${formValues.sexe}` },
        { text: `Adresse: ${formValues.adresse}` },
        { text: `Téléphone: ${formValues.telephone}` },
        { text: `Email: ${formValues.email}` },

        // Section Paiement
        { text: 'Détails de paiement', style: 'sectionHeader' },
        {
          table: {
            widths: ['*', '*', '*'],
            body: [
              ['Chèque', 'Espèces', 'Chèques Vacances'],
              [
                formValues.cheque ? '✓' : '',
                formValues.espece ? '✓' : '',
                formValues.chequesVacances ? '✓' : ''
              ]
            ]
          }
        },
        { text: `Montant: ${formValues.montant}`, margin: [0, 10, 0, 0] },
        { text: `Nom du payeur: ${formValues.nomPayeur}`, margin: [0, 10, 0, 0] },
        { text: `Banque: ${formValues.banque}`, margin: [0, 10, 0, 0] },

        // Heures d'entraînement
        { text: 'Heures d\'entraînement', style: 'sectionHeader' },
        { text: formValues.heuresEntrainement, margin: [0, 10, 0, 10] },

        // Photo
        { text: 'Photo (ajouter une photo à cet emplacement)', style: 'sectionHeader' },


        // Droit à l'image
        { text: 'Droit à l\'image', style: 'sectionHeader' },
        {
          text: `J'autorise ${formValues.droitImage ? 'Oui' : 'Non'} le personnel du Cercle des Nageurs Narbonnais à prendre des photos de mon enfant dans le cadre des activités associatives.`
        },

        { text: 'Signature (Faire précéder de la mention "lu et approuvé")', margin: [0, 10, 0, 10] },

        // Deuxième page du document
        { text: '', pageBreak: 'before' }, // Passage à la deuxième page

        { text: 'Personne à joindre en cas d\'accident', style: 'sectionHeader' },
        {
          columns: [
            { text: `Nom: ${formValues.nomUrgence}`, bold: true },
            { text: `Téléphone: ${formValues.telephoneUrgence}`, bold: true }
          ]
        },

        // Section Certificat Médical
        { text: 'Certificat Médical', style: 'sectionHeader' },
        { text: `Je soussigné(e), Docteur ${formValues.nomDocteur}, certifie que l'absence de contre-indication clinique à la pratique de la natation a été constatée.` },
        { text: `Date: ${formValues.dateCertificat}`, margin: [0, 10] },
        { text: `Signature et Cachet du Médecin:`, margin: [0, 10] },

        // Section Autorisation d'Hospitalisation
        { text: 'Autorisation d\'Hospitalisation', style: 'sectionHeader' },
        { text: `Je soussigné(e), ${formValues.nomParent}, autorise les dirigeants du Cercle des Nageurs Narbonnais à faire transporter mon enfant dans l'établissement hospitalier le plus proche en cas d'accident.` },

        // Signature finale
        { text: 'Fait à:', margin: [0, 20] },
        { text: `Le: ${formValues.dateSignature}`, margin: [0, 5] },
        { text: 'Signature', margin: [0, 5] },

        // Mentions RGPD
        { text: 'Mentions RGPD', style: 'sectionHeader' },
        {
          text: 'Les données recueillies via le formulaire d\'inscription ne seront utilisées qu\'à des fins informatives au bon fonctionnement de l\'association Cercle des Nageurs Narbonnais. Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement, ou exercer vos autres droits (opposition, portabilité, limitation).'
        },
        { text: 'Consultez le site cnil.fr pour plus d\'informations sur vos droits.', margin: [0, 10, 0, 10] }

      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 20, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        sectionHeader: {
          fontSize: 12,
          bold: true,
          decoration: 'underline',
          margin: [0, 10, 0, 5]
        },
        defaultStyle: {
          fontSize: 10
        }
      }
    };
  }
}
