<app-banner [title]="'Présentation du CNN'"
            [breadcrumb]="[['Accueil', 'index.html'], ['Présentation palais du travail', 'about']]"
            [bckUrl]="'about'"
></app-banner>
<div class="about-detl pt-100 pb-70">
    <div class="container">
        <div class="common-dtel-img">
            <img src="assets/img/about/piscine-narbonne-plage.webp" alt="About Images">
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-text">
                    <h2>{{aPropos?.attributes.titre}}</h2>
                    <div [innerHTML]="aProposContent"></div>
                    </div>
            </div>

            <div class="col-lg-4">
                <div class="common-offer">
                    <div class="drive-img">
                        <img src="assets/img/about/nager.webp" alt="nager à Narbonne">
                    </div>

                    <div class="common-offer-text">
                        <h3>Venez apprendre &agrave; nager et plus</h3>
                        <a href="tel:+33468320656" class="call-btn">Appeler</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="trainers-area pt-100 pb-70">
    <app-activites [lieu]="'narbonnePlage'"></app-activites>
 </section>

<section class="trainers-area pt-100 pb-70">
   <app-trainers></app-trainers>
</section>

