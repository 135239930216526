import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { PrivateService } from '../private.service';
//import { DropZoneDirective } from '../dropzone/dropzone.directive';
import { finalize } from 'rxjs/operators';
import { Fichier, Media } from 'src/app/services/models';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

    isHovering: boolean;
    percentCompleted = 0;
    affPB = false;
    affDoc = false;
    @Input() extension = 'image/png, image/jpeg';
    cardImageBase64: string;
    isImageSaved = false;
    @Input() phrase = 'Déposer un fichier PDF ou image (png, jpg)';

    message = '';

    fileName = '';
    uploadSub: Subscription;
    @Input() media: Fichier;
    resultMedia: Media;

    @Output() mediaId = new EventEmitter<number>();

    constructor(private service: PrivateService) { }

    ngOnInit(): void {
        if(this.media?.attributes?.hash) {
            console.log(this.media)
            this.resultMedia = {id: this.media.id, ...this.media.attributes};
            this.affDoc = true;
            this.affPB = true;
        }

    }

    cancelUpload() {
        this.uploadSub.unsubscribe();
        this.reset();
      }

      reset() {
        this.percentCompleted = null;
        this.uploadSub = null;

      }

    complete() {
        this.reset();
        this.affDoc = true;
    }

    supprimer() {
        this.service.delMedia(this.resultMedia.id).subscribe();
        this.affDoc = false;
        this.affPB = false;
        this.mediaId.emit(-1);
    }

    onFileSelected(event: any) {
        const formElement = new FormData();
        if(event.target) {// select file
            formElement.append('files', event.target.files[0], event.target.files[0].name);
        } else {// select by drag & drop
            console.log(event.target[0])
            formElement.append('files', event[0], event[0].name)
        }
        this.affPB = true


        //    this.service.uploadWithProgress(formElement).subscribe();

        const upload$ = this.service.uploadWithProgress(formElement)
        .pipe(
            finalize(() => this.complete())
        );

        this.uploadSub = upload$.subscribe((event: any) => {
            if (event.type == HttpEventType.UploadProgress) {
                this.percentCompleted = Math.round(100 * (event.loaded / event.total));
            }
            if(event.body?.length) {
                console.log(event.body[0].id);
                this.mediaId.emit(event.body[0].id);
                this.resultMedia = event.body[0];
            }

        })
    }

}
