import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Activite, Adherent, Adhesion, Creneau, Formation, SessionFormation, Tarif, Ville } from '../services/models';
import { ApiGetResponse, ApiGetSimpleResponse } from '../services/utils';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class PrivateService {

    private path = "/upload";
    private reqHeader: HttpHeaders;


  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(platformId)) {
        this.reqHeader = new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem(environment.tokenKey),
          });
    }
  }

  getPeriode() {
    return this.http.get<ApiGetResponse<any>>(`${environment.apiUrl}/parametres?filters[nom][$eq]=periode`, {headers: this.reqHeader});
  }

  genSelectAdherent(periode = true, activites: Activite[] = [], aucune = false, strSearch: string = null, custom = '') {
    let select = '';
    select += 'populate%5Badhesions%5D%5Bpopulate%5D%5B0%5D=creneaux&populate%5Badhesions%5D%5Bpopulate%5D%5B1%5D=activite&populate%5Bville%5D%5Bpopulate%5D%5B2%5D=ville&populate%5Buser%5D%5Bpopulate%5D%5B3%5D=user&populate%5Bcertificat%5D%5Bpopulate%5D%5B4%5D=certificat&populate%5Bphoto%5D%5Bpopulate%5D%5B2%5D=photo';
    if(custom.length) {
        select += '&' + custom;
        console.log(select);
    } else {
        if(periode) {
            select += `&filters[adhesions][periode]=${this.calcPeriode()}`;
        }
        select += '&sort[0]=nom&sort[1]=prenom';
        if(activites.length) {
            activites.forEach(element => {
                select += `&filters[adhesions][activite][id][$eq]=${element.id}`;
            });
        } else if(aucune) {
            select += '&filters[adhesions][id][$null]=true';
        }
        if(strSearch?.length) {
            const words = strSearch.split(' ');
            let i = 0;
            words.forEach((w, ind) => {
                select += `&filters[$and][${ind}][$or][${i}][nom][$containsi]=${w}`;
                select += `&filters[$and][${ind}][$or][${i+1}][prenom][$containsi]=${w}`;
                i+=2;
            });

        }
    }
    return select;
  }

  //getAdherents(periode = true, activites: Activite[] = [], aucune = false, strSearch: string = null, page = 1, pageSize = 10): Observable<ApiGetResponse<Adherent>> {

  getAdherents(select = '', page = 1, pageSize = 10): Observable<ApiGetResponse<Adherent>> {
    //let select = this.genSelectAdherent(periode, activites, aucune, strSearch);
    if(page > 0)
        select += `&pagination[pageSize]=${pageSize}&pagination[page]=${page}`;
    if(page === -1) //select only email
        select += '&fields[0]=email';
    console.log(select)
    return this.http.get<ApiGetResponse<Adherent>>(`${environment.apiUrl}/adherents?${select}`, {headers: this.reqHeader});
  }

  getAdherentByUserId(userId: number): Observable<ApiGetResponse<Adherent>> {
    return this.http.get<ApiGetResponse<Adherent>>(`${environment.apiUrl}/adherents?populate=*&filters[user][id][$eq]=${userId}`, {headers: this.reqHeader});
  }

  getAdherentById(id: number): Observable<ApiGetSimpleResponse<Adherent>> {
    return this.http.get<ApiGetSimpleResponse<Adherent>>(`${environment.apiUrl}/adherents/${id}?populate=*`, {headers: this.reqHeader});
  }

  findAdherent(nom: string, prenom: string, email: string, dateNais: Date = null): Observable<ApiGetResponse<Adherent>> {
    let select = 'populate=*';
    select += `&filters[nom][$containsi]=${nom}`;
    select += `&filters[prenom][$containsi]=${prenom}`;
    select += email != '' ? `&filters[email][$containsi]=${email}`: '';
    select += dateNais ? `&filters[dateNaissance][$eq]=${dateNais}` : '';
    console.log(select);
    return this.http.get<ApiGetResponse<Adherent>>(`${environment.apiUrl}/adherents?${select}`, {headers: this.reqHeader});
  }

  addAdherent(adh: Adherent): Observable<ApiGetSimpleResponse<Adherent>> {
    return this.http.post<ApiGetSimpleResponse<Adherent>>(`${environment.apiUrl}/adherents`, {data: adh}, {headers: this.reqHeader});
  }

  updateAdherent(id: number, adh: Adherent): Observable<ApiGetSimpleResponse<Adherent>> {
    return this.http.put<ApiGetSimpleResponse<Adherent>>(`${environment.apiUrl}/adherents/${id}`,{data: adh}, {headers: this.reqHeader});
  }

  uploadWithProgress(formData: FormData): Observable<any> {
    const path = "/upload";
    return this.http.post(`${environment.apiUrl}${path}`, formData, { headers: this.reqHeader, observe: 'events',  reportProgress: true });
  }

  delMedia(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/upload/files/${id}`, {headers: this.reqHeader});
  }


  getFiles(): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this.path}/files`);
  }


  getVilles(cp: string, ville: string=null): Observable<ApiGetResponse<Ville>> {
    let select = '';
    if(ville) {
        select = `&filters[nom_commune][$containsi]=${ville}`;
    }
    return this.http.get<ApiGetResponse<Ville>>(`${environment.apiUrl}/villes?filters[code_postal][$eq]=${cp}${select}`, {headers: this.reqHeader});
  }

  getActiviteById(id: number): Observable<ApiGetSimpleResponse<Activite>> {
    const sel = 'populate=*';//&filters[id][$eq]=${id}`;
    return this.http.get<ApiGetSimpleResponse<Activite>>(`${environment.apiUrl}/activites/${id}?${sel}`, {headers: this.reqHeader});
  }

  getActivites(lieu: string = '', idSuppr: number = null): Observable<ApiGetResponse<Activite>> {
    let sel = 'populate=*';
    if(lieu)
        sel += `&filters[${lieu}][$eq]=true`;
    if(idSuppr)
        sel += `&filters[id][$ne]=${idSuppr}`;

    return this.http.get<ApiGetResponse<Activite>>(`${environment.apiUrl}/activites?${sel}`, {headers: this.reqHeader});
  }

  getFormationById(id: number): Observable<ApiGetSimpleResponse<Formation>> {
    const sel = 'populate=*';//&filters[id][$eq]=${id}`;
    return this.http.get<ApiGetSimpleResponse<Formation>>(`${environment.apiUrl}/formations/${id}?${sel}`, {headers: this.reqHeader});
  }

  getSessionById(id: number): Observable<ApiGetSimpleResponse<SessionFormation>> {
    const sel = 'populate=*';//&filters[id][$eq]=${id}`;
    return this.http.get<ApiGetSimpleResponse<SessionFormation>>(`${environment.apiUrl}/session-formations/${id}?${sel}`, {headers: this.reqHeader});
  }

  getSessions(): Observable<ApiGetResponse<SessionFormation>> {
    const sel = 'populate=*';//&filters[id][$eq]=${id}`;
    return this.http.get<ApiGetResponse<SessionFormation>>(`${environment.apiUrl}/session-formations?${sel}`, {headers: this.reqHeader});
  }

  getCreneauxByActivite(act: number, bonnet: string): Observable<ApiGetResponse<Creneau>> {
    let select = bonnet.length ? `&filters[bonnet][$eq]=${bonnet}` : '';
    return this.http.get<ApiGetResponse<Creneau>>(`${environment.apiUrl}/creneaux?populate=*&filters[activite][id][$eq]=${act}${select}`, {headers: this.reqHeader});
  }

  getTarifs(idAct: number, first = true, tous = false): Observable<ApiGetResponse<Tarif>> {
    let select = `populate=*&filters[activites][id][$eq]=${idAct}`;
    if(tous) { //à utiliser pour sélectionner le tarif pour tous les créneaux
        select += `&filters[reference][$containsi]=tous`;
    }
    select += first ? '&filters[nom][$notContainsi]=seconde' : '&filters[nom][$containsi]=seconde';
   // select += '&sort[0]=tarif:desc';
    return this.http.get<ApiGetResponse<Tarif>>(`${environment.apiUrl}/tarifs?${select}`, {headers: this.reqHeader});
  }

  getTarif(idTar: number): Observable<ApiGetSimpleResponse<Tarif>> {
    return this.http.get<ApiGetSimpleResponse<Tarif>>(`${environment.apiUrl}/tarifs/${idTar}?populate=*`, {headers: this.reqHeader});
  }

  getTarifParDefaut(): Observable<ApiGetResponse<Tarif>> {
    return this.http.get<ApiGetResponse<Tarif>>(`${environment.apiUrl}/tarifs?populate=*&filters[parDefaut][$eq]=true`, {headers: this.reqHeader});
  }

  getAdhesionByAdherent(idAdherent: number): Observable<ApiGetResponse<Adhesion>> {
    return this.http.get<ApiGetResponse<Adhesion>>(`${environment.apiUrl}/adhesions?populate=*&filters[adherent][id][$eq]=${idAdherent}`, {headers: this.reqHeader});
  }

  getAdhesionById(idAdhesion: number): Observable<ApiGetSimpleResponse<Adhesion>> {
    return this.http.get<ApiGetSimpleResponse<Adhesion>>(`${environment.apiUrl}/adhesions/${idAdhesion}?populate=*`, {headers: this.reqHeader});
  }

  getAdhesionByUser(idUser: number): Observable<ApiGetResponse<Adhesion>> {
    return this.http.get<ApiGetResponse<Adhesion>>(`${environment.apiUrl}/adhesions?populate=*&filters[user][id][$eq]=${idUser}`, {headers: this.reqHeader});
  }

  getSessionsByAdherent(idAdherent: number): Observable<ApiGetResponse<SessionFormation>> {
    return this.http.get<ApiGetResponse<SessionFormation>>(`${environment.apiUrl}/session-formations?populate=*&filters[adherent][id][$eq]=${idAdherent}`, {headers: this.reqHeader});
  }

  addAdhesion(adh: any): Observable<ApiGetSimpleResponse<Adhesion>> {
    return this.http.post<ApiGetSimpleResponse<Adhesion>>(`${environment.apiUrl}/adhesions`, {data: adh}, {headers: this.reqHeader});
  }

  getAdhesions(): Observable<ApiGetSimpleResponse<Adhesion[]>> {
    return this.http.get<ApiGetResponse<Adhesion>>(`${environment.apiUrl}/adhesions?populate=*`, {headers: this.reqHeader});
  }
  updateAdhesion(id: number, paye: boolean): Observable<ApiGetSimpleResponse<Adhesion>> {
    return this.http.put<ApiGetSimpleResponse<Adhesion>>(`${environment.apiUrl}/adhesions/${id}`, {data: {paye: paye}}, {headers: this.reqHeader});
  }

  delAdhesion(id: number): Observable<ApiGetSimpleResponse<Adhesion>> {
    return this.http.delete<ApiGetSimpleResponse<Adhesion>>(`${environment.apiUrl}/adhesions/${id}`, {headers: this.reqHeader});
  }

  calcPeriode(): string {
    //const an = moment(moment.now()).toLocaleString().substring(11,15);
    const date = moment(moment.now()).toISOString();
    const an = date.substring(0, 4);
    const mois = Number(date.substring(5, 7));
    let periode = (Number(an)-1).toString() + ' / '+an;
    if(mois >= 8) {
         periode = an+' / '+(Number(an)+1).toString();
    }
    return periode;
  }

  whatBonnet(dateNaissance: string) {
    //calcul de l'age et détermination bonnet
    let bonnet = '';
    const now = new Date();
    const dateNais = new Date(dateNaissance);
    const age = now.getFullYear() - dateNais.getFullYear();
    if(age > 4) {
        if(age > 5) {
            if(age > 7) {
                if(age > 9) {
                    if(age < 18) {
                        bonnet = 'ADO';
                    } else {
                        bonnet = 'ADULTE';
                    }
                } else {
                    bonnet = 'NOIR';
                }
            } else {
                bonnet = 'BLEU';
            }
        } else {
            bonnet = 'BLANC';
        }
    } else {
        if(age > 3) {
            bonnet = 'ROUGE';
        }
    }
    return bonnet;
  }
}
