<div class="row">
    <div class="col-lg-4 col-md-6" *ngFor="let article of news">
        <div class="news-card">
            <div class="news-img">
                <a routerLink="/news-details"><img class="" [src]="imgServ.imageUrl(article.attributes.photoCouverture,'570x400')" alt="News Images"></a>
                <div class="sub-text">
                    <a routerLink="/news">{{article.attributes.categorie.data?.attributes?.nom.toUpperCase()}}</a>
                </div>
            </div>
            <ul>
                <li><a routerLink="/news">{{article.attributes.auteur}}</a> </li>
                <li>{{article.attributes.updatedAt | date:'dd-MM-YYYY'}}</li>
            </ul>
            <div class="news-content">
                <a routerLink="/news-details"><h3>{{article.attributes.titre}}</h3></a>
                <p [innerHTML]="article.attributes.resume"></p>
                <a routerLink="/news-details" class="news-icon">Lire plus</a>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-md-12" *ngIf="affPagination">
        <div class="pagination-area">
            <a routerLink="/news" class="prev page-numbers"><i class="fas fa-chevron-left"></i></a>
            <a routerLink="/news" class="page-numbers current" aria-current="page">1</a>
            <a routerLink="/news" class="page-numbers ">2</a>
            <a routerLink="/news" class="page-numbers">3</a>
            <a routerLink="/news" class="next page-numbers"><i class="fas fa-chevron-right"></i></a>
        </div>
    </div>
</div>
