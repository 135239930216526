

<div class="home-slider">
    <div class="slider-area owl-carousel owl-theme">
        <div class="slider-item slider-bg2">
            <div class="d-table" [style]="'background-image: url('+sliderImg[0]+'); background-repeat: no-repeat; background-size: cover;'">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="bg-white/70 p-8 w-4/6 rounded-xl">
                            <h1>{{ slider[0]?.attributes.titre }}</h1>
                            <div class="desc" [innerHtml]="slider[0]?.attributes.description"></div>
                            <br />
                            <div class="slider-btn">
                                <a routerLink="/about" class="default-btn1">En savoir plus</a>
                                <a routerLink="/contact" class="default-btn2 ml-20">Contactez nous</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item slider-bg2">
            <div class="d-table" [style]="'background-image: url('+sliderImg[1]+'); background-repeat: no-repeat; background-size: cover;'">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="bg-white/70 p-8 w-4/6 rounded-xl">
                            <h1>{{ slider[1]?.attributes.titre }}</h1>
                            <div class="desc" [innerHtml]="slider[1]?.attributes.description"></div>
                            <br />
                            <div class="slider-btn">
                                <a routerLink="/about" class="default-btn1">En savoir plus</a>
                                <a routerLink="/contact" class="default-btn2 ml-20">Contactez nous</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item slider-bg3">
            <div class="d-table" [style]="'background-image: url('+sliderImg[2]+'); background-repeat: no-repeat; background-size: cover;'">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="bg-white/70 p-8 w-4/6 rounded-xl">
                            <h1>{{ slider[2]?.attributes.titre }}</h1>
                            <div class="desc" [innerHtml]="slider[2]?.attributes.description"></div>
                            <br />
                            <div class="slider-btn">
                                <a routerLink="/about" class="default-btn1">En savoir plus</a>
                                <a routerLink="/contact" class="default-btn2 ml-20">Contactez nous</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="home-slider" *ngIf="isSlider === 'slider'">
    <div class="swiper-container">
        <div class="swiper-wrapper">

        </div>
    </div>
</div>
<div *ngIf="isSlider === 'banner'">
    <div class="banner-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-content" *ngIf="header">
                                <h1>{{header.attributes.titre}}</h1>
                                <div [innerHTML]="headerContent"></div>
                                <div class="banner-btn">
                                    <a routerLink="/about" class="banner-btn1 border-radius">En savoir plus</a>
                                    <a routerLink="/contact" class="banner-btn2 border-radius ml-20">Contactez nous</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0">
                            <div class="banner-img">
                                <img src="assets/img/banner-img.webp" alt="Banner Images">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<section class="news-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Nos actualités</span>
            <h2>{{actualites?.attributes?.titre}}</h2>
            <div [innerHTML]="actualitesContent"></div>
        </div>


        <app-liste-news></app-liste-news>
    </div>
</section>

<div class="about-another pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-img2">
                    <img src="assets/img/about/2.webp" alt="About Images">

                    <div class="icon-shape-5"><i class="flaticon-sea"></i></div>
                    <div class="icon-shape-6"><i class="flaticon-sea"></i></div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="about-content" *ngIf="aPropos">
                    <span>A propos</span>
                    <h2>{{aPropos?.attributes?.titre}}</h2>
                    <div [innerHTML]="aproposContent"></div>
                    <div class="about-more-btn">
                        <a routerLink="/about" class="default-btn4">Lire la suite</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-area-two ptb-100">
    <app-activites></app-activites>
</section>



<app-gallerie [photos]="photos" [photosContent]="photosContent"></app-gallerie>

<div class="testimonial-area ptb-100">

   <app-trainers [isHome]="true"></app-trainers>
</div>




