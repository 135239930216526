import { Param } from './../../../services/models';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    adresse: string = '';
    horaires: string = '';
    tel: string = '';
    telValue: string = '';
    telNPValue: string = '';
    telNP: string = '';

  constructor(private service: ApiService) { }



  ngOnInit(): void {
    this.service.getParam().subscribe({
        next: res => {
            this.adresse = this.service.getParamValue('adresse', res.data, true);
            this.horaires = this.service.getParamValue('horaires', res.data, true);
            this.tel = this.service.getParamValue('telephone', res.data, true, true);
            this.telNP = this.service.getParamValue('Tel-Narbonne-Plage', res.data, true, true);

            this.telValue = this.service.getParamValue('telephone', res.data)
                            .replace('<BR>','')
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('.', '')
                            .replace('/', '');
            this.telNPValue = this.service.getParamValue('Tel-Narbonne-Plage', res.data)
                            .replace('<BR>','')
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('.', '')
                            .replace('/', '');
        }
    });
  }

}
