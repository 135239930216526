import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivateService } from 'src/app/private/private.service';
import { Activite } from 'src/app/services/models';

@Component({
  selector: 'app-find-activites',
  templateUrl: './find-activites.component.html',
  styleUrls: ['./find-activites.component.scss']
})
export class FindActivitesComponent {

    listeActivites: Activite[] = [];
    @Input() aucune = false;
    @Input() activitesSelected: Activite[] = [];
    @Output() sendActSel: EventEmitter<Activite[]> = new EventEmitter();
    @Output() sendAucune: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private privServ: PrivateService
    ) {
        this.privServ.getActivites().subscribe({
            next: res => {
                this.listeActivites = res.data;
            }
        });
    }

    isSelected(activite) {
        const ind = this.activitesSelected.findIndex(a => a.id === activite.id);
        return ind === -1 ? false : true;
    }

    selectAct(activite) {
        if(this.isSelected(activite))
            this.activitesSelected.splice(this.activitesSelected.findIndex(a => a.id === activite.id),1);
        else
            this.activitesSelected.push(activite);

        this.sendActSel.emit(this.activitesSelected);
    }

    selAucune() {
        this.aucune = !this.aucune;
        this.activitesSelected = [];
        this.sendAucune.emit(this.aucune);
    }
}
