


    <div class="text-gray-500 m-4 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" appDropZone (dropped)="onFileSelected($event)"
        [class.hovering]="isHovering" *ngIf="!affPB; else progressBar">
      <div class="space-y-1 text-center">
        <svg class="mx-auto h-12 w-12 text-pink-500" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <p class="pb-20 text-sm">{{phrase}}</p>
          <div class="file ">
              <label class="file-label">
                    <form>
                        <input type="file" name="files" (change)="onFileSelected($event)" style="display: none;" [accept]="extension">
                    </form>
                    <span class="file-cta">
                    <span class="file-icon">
                    </span>
                    <span class="file-label text-gray-500 cursor-pointer">ou choisir un fichier...</span>
                    </span>
              </label>
          </div>
          <div *ngIf="message" class="alert alert-secondary" role="alert">{{ message }}</div>
      </div>
    </div>


  <ng-template #progressBar>
        <div class="relative pt-1 p-20 mt-20" *ngIf="!affDoc else document">
          <div class="flex mb-2 items-center justify-between">
            <div>
              <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200" *ngIf="percentCompleted<100; else complete">
                Import en cours...
              </span>
              <ng-template #complete>
                  <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                      Importation termin&eacute;e
                    </span>
              </ng-template>
            </div>
            <div class="text-right">
              <span class="text-xs font-semibold inline-block text-green-600">
                {{percentCompleted}}%
              </span>
            </div>
          </div>
          <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
            <div [style]="'width: '+percentCompleted+'%'" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500">

            </div>
          </div>
        </div>
        <ng-template #document>
            <div class="flex justify-around items-center align-middle">
                <div><p class="text-gray-900 dark:text-gray-50 px-4">{{resultMedia?.name}}</p></div>
                <div><button (click)="supprimer()" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Supprimer</button></div>
            </div>
        </ng-template>

  </ng-template>



