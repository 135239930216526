<div class="mt-28 print:mt-0 flex justify-center bg-white text-black">
    <div class="grid gap-1 grid-cols-2">
        <div *ngFor="let adh of listeAdherents" class="border border-1 w-[350px] h-[200px] p-2">
            <div class="carte text-gray-600 font-light text-sm">
                <div class="first-row-1">
                    <div class="text-center mb-6 w-full">Cercle des Nageurs Narbonnais<br />Saison 2023-2024</div>
                    <div >
                        NOM:&nbsp;<span class="text-black font-medium">{{adh.attributes.nom}}</span><br/>
                        Prénom:&nbsp;<span class="text-black font-medium">{{adh.attributes.prenom}}</span><br />
                        Année de naissance:&nbsp;<span class="text-black font-medium">{{adh.attributes.dateNaissance | date:'YYYY'}}</span>
                    </div>
                </div>
                <div class="first-row-2">
                    <img class="w-full" [src]="photoUrl(adh)" />
                </div>
                <div class="Second-row">
                    {{adh.attributes.adhesions?.data[0]?.attributes?.activite?.data?.attributes?.nom}}&nbsp;-&nbsp;
                    {{genereCreneaux(adh.attributes.adhesions?.data[0]?.attributes?.creneaux?.data)}}
                </div>
                <div class="third-row self-end">
                    <div class="w-full text-center">
                        Tél: 04 68 32 06 56 - contact&#64;cnnarbonne.fr
                    </div>
                </div>
              </div>
        </div>
    </div>
</div>
