<div class="flex justify-between items-end">
    <div class="col-lg-6 col-md-6">
        <div class="form-group">
            <label class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Votre Code Postal</label>
            <input (keyup)="getVilles($event)" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Code postal">
        </div>
    </div>

    <div class="col-lg-6 col-md-6">
        <div class="form-group">
            <label>&nbsp;</label>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="relative inline-block text-left">
            <div>
            <button (click)="affDropdown = !affDropdown" type="button" class="inline-flex w-full justify-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                {{nomVille}}
                <!-- Heroicon name: mini/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
            </button>
            </div>

            <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div *ngIf="affDropdown" class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-gray-50 dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <button type="submit" *ngFor="let ville of villes; index as i" (click)="changeVille(ville.id)" class="text-gray-700 dark:text-gray-50 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" [id]="'menu-item-'+i">{{ville.attributes.nom_commune}}</button>

                <!--<form method="POST" action="#" role="none">
                <button type="submit" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                </form>-->
            </div>
            </div>
            </div>
        </div>
    </div>
</div>
