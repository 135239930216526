<div class="container">
    <div class="scetion-title text-center mb-50" *ngIf="prestations && !inscription">
        <span>Nos prestations</span>
        <h2>{{prestations?.attributes.titre}}</h2>
        <p>{{sanitize(prestations?.attributes.content)}}</p>
    </div>

    <div class="row" *ngIf="!inscription">
        <div class="col-lg-4 col-md-6 mb-6" *ngFor="let activite of activites">
            <div class="service-item box-shadow h-full">
                <a [routerLink]="activite.attributes.urlSpecifique ?
                    activite.attributes.urlSpecifique
                    : '/activites/'+activite.id"
                    class="service-item-icon"><i [ngClass]="(activite.attributes.icone) ? activite.attributes.icone : 'flaticon-sea'"></i></a>
                <a [routerLink]="activite.attributes.urlSpecifique ?
                   activite.attributes.urlSpecifique
                    : '/activites/'+activite.id"
                    class="service-head"><h3>{{activite.attributes.nom}}</h3></a>
                <p>{{activite.attributes.resume}}</p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="inscription">
        <div class="col-lg-4 col-md-6" *ngFor="let activite of activites">
            <div class="service-item box-shadow">
                <a [routerLink]="'/private/adhesion/add/'+activite.id" [queryParams]="{idAdh: idAdherent}" class="service-item-icon"><i [ngClass]="(activite.attributes.icone) ? activite.attributes.icone : 'flaticon-sea'"></i></a>
                <a [routerLink]="'/private/adhesion/add/'+activite.id" [queryParams]="{idAdh: idAdherent}" class="service-head"><h3>{{activite.attributes.nom}}</h3></a>
            </div>
        </div>
    </div>
</div>
