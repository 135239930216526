import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { InscriptionService } from 'src/app/services/inscription.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrl: './inscription.component.scss'
})
export class InscriptionComponent implements OnInit {
  inscriptionForm: FormGroup;
  activites = [
    { activite: 'Aquagym'},
    { activite: 'Marche Aquatique'},
    { activite: 'École de Natation'},
    { activite: 'Jardin Aquatique'},
    { activite: 'Natation Sportive'},
    { activite: 'Water Polo'},
    { activite: 'BNSSA'},
    { activite: 'ADOS / ADULTES'}
  ];

  constructor(private pdfService: InscriptionService, private fb: FormBuilder) {}

  ngOnInit() {
    this.inscriptionForm = new FormGroup({
      nom: new FormControl('', Validators.required),
      prenom: new FormControl('', Validators.required),
      dateNaissance: new FormControl('', Validators.required),
      sexe: new FormControl('', Validators.required),
      adresse: new FormControl('', Validators.required),
      telephone: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      cheque: new FormControl(false),
      espece: new FormControl(false),
      chequesVacances: new FormControl(false),
      montant: new FormControl('', Validators.required),
      nomPayeur: new FormControl('', Validators.required),
      banque: new FormControl('', Validators.required),
      heuresEntrainement: new FormControl(''),
      activites: this.fb.array([]),
      droitImage: new FormControl(false),
      nomUrgence: new FormControl('', Validators.required),
      telephoneUrgence: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
      nomDocteur: new FormControl('', Validators.required),
      dateCertificat: new FormControl('', Validators.required),
      nomParent: new FormControl('', Validators.required),
      dateSignature: new FormControl('', Validators.required)
    });
  }

  onChange(activite: string, isChecked: boolean) {
    const activitesFormArray = <FormArray>this.inscriptionForm.controls.activites;

    if (isChecked) {
      activitesFormArray.push(new FormControl(activite));
    } else {
      let index = activitesFormArray.controls.findIndex(x => x.value == activite)
      activitesFormArray.removeAt(index);
    }
  }

  onSubmit() {
    console.log(this.inscriptionForm.value);
    if (!this.inscriptionForm.errors) {


      //const formData = { ...this.inscriptionForm.value, activites: selectedActivites };
      this.pdfService.generatePdf(this.inscriptionForm.value);
    } else {
      console.log('Formulaire non valide');
    }
  }
}
