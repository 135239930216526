import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { JwtRequest, JwtResponse, User } from '../../services/models';
import { tap, shareReplay, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';

const TOKEN_NAME = environment.tokenKey;
const EXPIRES_AT = 'expires_at';
const USER = 'user';
const ROLE= 'role';
//const CURRENT_USER = 'user';
//const USER_ROLE = 'role';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private expiration = 1; //nb de jours pour l'expiration du token
  private baseURL = environment.apiUrl;
  role: BehaviorSubject<string> = new BehaviorSubject('');
  isLogged = new BehaviorSubject<boolean>(false);
  isAdmin: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
    ) { }

  private reqHeader = new HttpHeaders({
    Authorization: 'Bearer ' + this.token
  });


  register(credentials: any) {
    const fullURL = `${this.baseURL}/auth/local/register`;
    this.http
    .post<any>(fullURL, credentials)
      .subscribe(createdUser => {
        this.setSession(createdUser);
        this.router.navigate(['/dashboard']);
      });
  }

  login(credential: any): Observable<JwtResponse> {
    let jwtRequest: JwtRequest = credential;
    return this.http.post<JwtResponse>(`${environment.apiUrl}/auth/local`,
        jwtRequest).pipe(
            tap((resp: JwtResponse) => this.setSession(resp)),
            shareReplay()
        );
  }

  private setSession(authResult: JwtResponse) {
    this.clearStorage();
    //let d = new Date(Date.now());
    const expiresAt = this.decodePayloadToken(authResult.jwt).exp;
    this.reqHeader = new HttpHeaders({
      Authorization: 'Bearer ' + authResult.jwt
    });
    // this.getUser(authResult.user.id).subscribe({
    //   next: usr => {
    //     console.log(usr);
    //     this.role.next(usr.role.name);
    //     this.getMembreInApi(usr.membre.id).subscribe({
    //       next: mbr => this.membre.next(mbr),
    //       error: err => console.error(err)
    //     });
    this.http.get<User>(`${environment.apiUrl}/users/${authResult.user.id}?populate=*`, {headers: this.reqHeader}).subscribe({
        next: usr => {
            this.role.next(usr.role.name);
            if(usr.role.name === 'collaborateur') {
                this.isAdmin = true;
            }
            if(isPlatformBrowser(this.platformId)) {
                this.role.next(usr.role.name);
                sessionStorage.setItem(ROLE, usr.role.name);
            }
        }
    });
    if(isPlatformBrowser(this.platformId)) {
        sessionStorage.setItem(TOKEN_NAME, authResult.jwt);
        sessionStorage.setItem(USER, JSON.stringify(authResult.user));
        sessionStorage.setItem(EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));
    }
    this.isLogged.next(true);
    this.router.navigateByUrl('/private');
    //   },
    //   error: err => console.log(err)
    // });

    //localStorage.setItem(CURRENT_USER, authResult.user.id.toString());

  }

  session(authResult: JwtResponse | undefined) {
    this.setSession(authResult);
  }

  private clearStorage() {
    if(isPlatformBrowser(this.platformId)) {
        sessionStorage.removeItem(TOKEN_NAME);
        sessionStorage.removeItem(USER);
        sessionStorage.removeItem(EXPIRES_AT);
        sessionStorage.clear();
    }
    //localStorage.removeItem(CURRENT_USER);
  }

  logout(url = '/private') {
    this.role.next('');
    this.isLogged.next(false);
    this.isAdmin = false;
    this.clearStorage();
    this.router.navigate([url]);
  }

  isTokenExpired(): boolean {
    let expiration = this.getExpiration();
    if (expiration) {
      return (moment.now() < expiration.valueOf());
    }

    return false;
  }

  getExpiration() {
    if(isPlatformBrowser(this.platformId)) {
      const expiration = sessionStorage.getItem(EXPIRES_AT);
      const expiresAt = Number(expiration) * 1000;
      return moment(expiresAt);
    } else {
      return null;
    }
  }

  // isLoggedIn(): boolean {
  //   let loggedIn: boolean = false;
  //   console.log(moment().valueOf());
  //   if (this.token) {
  //     loggedIn = !this.isTokenExpired();
  //     if(loggedIn)
  //       this.role.next(this.user.role?.name);
  //   }

  //   return loggedIn;
  // }

  public isLoggedIn() {
    if(isPlatformBrowser(this.platformId)) {
        if(sessionStorage.getItem(ROLE) === 'collaborateur') {
            this.role.next('collaborateur');
        }
    }
    return moment().isBefore(this.getExpiration());
  }

  isAdminLoggedIn() {
    const dateok = moment().isBefore(this.getExpiration());
    if(dateok) {

        if(isPlatformBrowser(this.platformId)) {
            if(sessionStorage.getItem(ROLE) === 'collaborateur') {
                this.role.next('collaborateur');
                return true;
            } else return false;
        } else return false;
    } else return false;
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  // private getExpiration(): number {
  //   let expiresAt: number = 0;
  //   if(isPlatformBrowser(this.platformId)) {
  //       const expiration = sessionStorage.getItem(EXPIRES_AT);

  //       if (expiration) {
  //           expiresAt = Number(expiration);
  //       }
  //   }

  //   return expiresAt;
  // }

  get token(): string {
    if(isPlatformBrowser(this.platformId))
        return sessionStorage.getItem(TOKEN_NAME);
    else
        return null;
  }
  get user(): any {
    if(isPlatformBrowser(this.platformId))
        return JSON.parse(sessionStorage.getItem(USER));
    else
        return null;
  }
  get roleApi(): any {
    let role = '';
    if(this.user) {
      return this.http.get<User>(`${environment.apiUrl}/users/${this.user.id}?populate=*`, {headers: this.header})
        // .subscribe({
        //     next: resUsr => this.role.next(resUsr.role.name),
        //     complete: () => (role === 'collaborateur')? true : false
        // });
            .pipe(map(usr => {
              return usr.role.name;
            }));
    } else {
      return null;
    }
  }

//   get isAdmin(): any {
//     if(isPlatformBrowser(this.platformId)) {
//         console.log(sessionStorage.getItem(ROLE))
//         if(sessionStorage.getItem(ROLE) === 'collaborateur'){
//             return true;
//          } else {
//             return false;
//          }
//     }
//      else return false;
//   }

  get header(): any {
    return new HttpHeaders({
      Authorization: 'Bearer ' + this.token
    });

  }
  get headerJSON(): any {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token
    });
  }
  get headerJSONWithoutAuth(): any {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  private decodePayloadToken(token: string) {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload;
  }

  forgotPassword(email) {
    return this.http.post<any>(`${environment.apiUrl}/auth/forgot-password`, { email: email }, {headers: this.headerJSONWithoutAuth})
      .pipe(map(response => {
          return response;
      }));
  }

  resetPassword(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/auth/reset-password`, data, {headers: this.headerJSONWithoutAuth})

  }

}
