<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-top">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Accueil</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pr&eacute;sentation <i class="fas fa-chevron-right"></i></a>
                            <!--<a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"></a>-->
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about/palais-du-travail" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Palais du travail</a></li>

                                <li class="nav-item"><a routerLink="/about/narbonne-plage" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Narbonne plage</a></li>


                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Activit&eacute;s <i class="fas fa-chevron-right"></i></a>
                            <!--<a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"></a>-->
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let activite of activites">
                                    <a [routerLink]="activite.attributes.urlSpecifique ?
                                        activite.attributes.urlSpecifique
                                        : '/activites/'+activite.id"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                            {{activite.attributes.nom}}
                                    </a>
                                </li>
                            </ul>
                        </li>


                        <li class="nav-item">
                            <a routerLink="/courses" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Formations</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>

                    </ul>

                    <div class="other-side flex items-center">


                            <ul class="navbar-nav m-auto">
                                <li class="nav-item">
                                    <!-- <a routerLink="/login" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="!isLogged">Se connecter</a> -->
                                    <a routerLink="/inscription" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Inscription</a>
                                    <!-- <a href="#" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="isLogged" (click)="logout()">Se déconnecter</a> -->

                                    <!-- <a href="javascript:void(0)" class="nav-link" *ngIf="isLogged">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>
                                    </a> -->
                                    <!--<a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"></a>-->
                                    <!-- <ul class="dropdown-menu" *ngIf="isLogged">
                                        <li class="nav-item"><a routerLink="/private" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mon compte</a></li>
                                        <li class="nav-item"><a routerLink="/" (click)="logout('/')" class="nav-link">Se déconnecter</a></li>
                                    </ul> -->
                                </li>
                            </ul>

                        </div>

                        <div class="modal-menu">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#myModal2">
                                <i class="flaticon-menu"></i>
                            </a>
                        </div>
                    </div>
            </nav>
        </div>
    </div>
</div>

<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fas fa-times"></i></span></button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/"><img src="assets/img/logo.webp" alt="Logo"></a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">A propos de nous</h3>
                        <p>Le Cercle des Nageurs Narbonnais propose une multitude d'activités à partir de 4 ans.</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Votre espace</h3>
                        <ul>
                            <li *ngIf="!isLogged"><a routerLink="/login" data-bs-dismiss="modal">Se connecter</a></li>
                            <li *ngIf="!isLogged"><a routerLink="/login/register" data-bs-dismiss="modal">S'inscrire</a></li>
                            <li *ngIf="isLogged"><a routerLink="/private" data-bs-dismiss="modal">Mon compte</a></li>
                            <li *ngIf="isLogged"><a routerLink="/" (click)="logout('/')" data-bs-dismiss="modal">Se d&eacute;connecter</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contacts</h3>

                        <ul class="contact-info">
                            <li><i class="fas fa-map-marker-alt"></i> Addresse <span [innerHTML]="adresse"></span></li>
                            <li><i class="far fa-envelope"></i> Courriel <a [href]="'mailto:'+email"><span [innerHTML]="email"></span></a></li>
                            <li><i class="fas fa-mobile-alt"></i> Tel Narbonne <a [href]="'tel:'+telValue"><span [innerHTML]="tel"></span></a></li>
                            <li><i class="fas fa-mobile-alt"></i> Tel Narbonne-Plage <a [href]="'tel:'+telNPValue"><span [innerHTML]="telNP"></span></a></li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Restons en contact</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class='fab fa-facebook-f'></i></a></li>
                            <li><a href="#" target="_blank"><i class='fab fa-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='fab fa-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='fab fa-google-plus-g'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
