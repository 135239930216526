import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

export interface Pagination {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

export interface ApiGetResponse<T> {
    data: Array<T>;
    meta: {
          pagination: Pagination
      }
  }
  export interface ApiResponse<T> {
    T: any;
  }
  export interface ApiGetSimpleResponse<T> {
    data: T;
    meta: {pagination: Pagination};
  }
@Injectable({
    providedIn: 'root'
})
export class Utils {

    constructor(private sanitizer: DomSanitizer) { }


    sanitizeURL(value: string): any {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
    sanitizeHTML(value: string = ''): any {
        if(value?.length)
            return this.sanitizer.bypassSecurityTrustHtml(value);
        else
            return '';
    }
    unique(obj: any ) {
        const uniqueArray = obj.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === obj.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
            });
        });
        return uniqueArray;
    }
  }

export class UniqueNameSet extends Set {
    constructor(values) {
        super(values);

        const names = [];
        for (let value of this) {
            if (names.includes(value.name)) {
                this.delete(value);
            } else {
                names.push(value.name);
            }
        }
    }
}
