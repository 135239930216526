import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ImagesService } from 'src/app/services/images.service';

@Component({
  selector: 'app-trainers',
  templateUrl: './trainers.component.html',
  styleUrls: ['./trainers.component.scss']
})
export class TrainersComponent implements OnInit, OnChanges {

    collaborateurs: Array<any> = [];
    @Input() idToRemove: string = null;
    @Output() idChange = new EventEmitter<string>();
    @Input() isHome: Boolean = false;

  constructor(private serviceApi: ApiService, public imgServ: ImagesService) { }

  ngOnInit(): void {
      let sel = 'filters[fonctions][code][$eq]=MN';
      if(this.idToRemove) {
        sel += `&filters[id][$ne]=${this.idToRemove}`;
      }
      sel += '&sort[0]=nom&sort[1]=prenom';
       this.serviceApi.getCollaborateurs(sel, true)
       .subscribe({
           next: res => this.collaborateurs = res.data,
           error: err => console.log(err),
        });

   }

   ngOnChanges(changes: SimpleChanges): void {
    if(this.idToRemove) {
       if(changes.idToRemove.currentValue !== changes.idToRemove.previousValue) {
        this.ngOnInit();
       }
    }
   }

   nomPrenom(n, p) { return `${n} ${p}`; }

   sendIdCollaborateur(id) {
      this.idChange.emit(id);
   }
}
