import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Activite, Composant } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-palais',
  templateUrl: './palais.component.html',
  styleUrls: ['./palais.component.scss']
})
export class PalaisComponent implements OnInit {
    aPropos: Composant;
    aProposContent: string;

    constructor(private service: ApiService, private utils: Utils) { }

    ngOnInit(): void {
        this.service.getComposants('presentation-palais', 'a propos').subscribe({
            next: res => {
                this.aPropos = res.data[0];
                this.aProposContent = this.utils.sanitizeHTML(res.data[0].attributes.contenu);
            }
        });
    }
}
