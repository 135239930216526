import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiGetResponse, ApiGetSimpleResponse, ApiResponse, Pagination } from '../services/utils';
import { Adherent, Adhesion, User, TemplateEmail, MailingList } from '../services/models';

@Injectable({
  providedIn: 'root'
})
export class AdminServiceService {

    private path = "/upload";
    private reqHeader: HttpHeaders;
    // listeAdh$: BehaviorSubject<string> = new BehaviorSubject('');
    // listeAdh = this.listeAdh$.asObservable();


  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(platformId)) {
        this.reqHeader = new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem(environment.tokenKey)
          });
    }
  }

//   updateListeAdherents(liste: Adherent[]) {
//     this.listeAdh$.next(liste);
//   }

  getAdhesions(page = 1, pageSize = 10): Observable<ApiGetSimpleResponse<Adhesion[]>> {
    let select = 'populate=*';
    select += '&sort[0]=adherent.nom&sort[1]=adherent.prenom';
    select += `&pagination[pageSize]=${pageSize}&pagination[page]=${page}`;
    return this.http.get<ApiGetSimpleResponse<Adhesion[]>>(`${environment.apiUrl}/adhesions?${select}`, {headers: this.reqHeader});
  }
  updateAdhesion(id: number, paye: boolean): Observable<ApiGetSimpleResponse<Adhesion>> {
    return this.http.put<ApiGetSimpleResponse<Adhesion>>(`${environment.apiUrl}/adhesions/${id}`, {data: {paye: paye}}, {headers: this.reqHeader});
  }
  getUserAdmin(): Observable<ApiResponse<User>>{
    return this.http.get<ApiResponse<User>>(`${environment.apiUrl}/users?populate=*&filters[username][$eq]=CNN`, {headers: this.reqHeader});
  }

  last(pag: Pagination) {
    if (pag) {
        if(pag.pageCount === pag.page)
            return pag.total;
        else
            return pag?.page*pag?.pageSize;
    } else
        return null;
    }

    generateTabPage(p: Pagination) {
        let tabPage: Array<any> = [];
        if(p.pageCount <= 5) {
            for (let index = 0; index < p.pageCount; index++) {
                tabPage[index] = index+1;
            }
        } else if (p.page <= 3) {
            tabPage = [1,2,3,'...',p.pageCount];
        } else if (p.page <= (p.pageCount-3)) {
            tabPage = [1,'...',p.page,'...',p.pageCount];
        } else {
            tabPage = [1,'...',p.pageCount-2,p.pageCount-1,p.pageCount]
        }
        return tabPage;
    }

    updateCarte(listeAdh: Adherent[]): Observable<ApiGetSimpleResponse<any>> {
        return this.http.put<ApiGetSimpleResponse<any>>(`${environment.apiUrl}/carte`, {data: {listeAdherents: listeAdh}}, {headers: this.reqHeader});
    }

    getCarte():Observable<ApiGetSimpleResponse<any>> {
        return this.http.get<ApiGetSimpleResponse<any>>(`${environment.apiUrl}/carte`, {headers: this.reqHeader})
    }

    getTemplateEmail(): Observable<ApiGetResponse<TemplateEmail>> {
        return this.http.get<ApiGetResponse<TemplateEmail>>(`${environment.apiUrl}/email-templates`, {headers: this.reqHeader});
    }

    addMailingList(liste: MailingList[]) {
        this.http.get<ApiGetResponse<any>>(`${environment.apiUrl}/mailing-lists`, {headers: this.reqHeader}).subscribe({
            next: res => {
                res.data.forEach(el => {
                    this.http.delete<ApiGetSimpleResponse<any>>(`${environment.apiUrl}/mailing-lists/${el.id}`, {headers: this.reqHeader})
                    .subscribe({error: err => console.error(err)});
                });
            },
            complete: () => {
                liste.forEach(element => {
                    this.http.post<any>(`${environment.apiUrl}/mailing-lists`, {data: element}, {headers: this.reqHeader})
                    .subscribe({error: err => console.error(err)});
                });
            },
            error: err => console.error(err)
        });

    }

    sendEmail(form: any): Observable<ApiGetSimpleResponse<TemplateEmail>> {
        const val = {subject: form.subject, content: form.body};
        return this.http.put<ApiGetSimpleResponse<TemplateEmail>>(`${environment.apiUrl}/email-templates/${form.id}`, {data: val}, {headers: this.reqHeader})

    }

    getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    public photoProfile(adh: Adherent) {
        if(adh.attributes.photo?.data?.attributes?.url) {
            return environment.urlUploads+adh.attributes.photo?.data?.attributes?.url;
        } else {
            const colors = ['bleu', 'jaune', 'orange', 'rouge', 'vert', 'violet'];
            const alea = this.getRandomInt(colors.length);
            return `/assets/admin/avatar-${colors[alea]}.png`;
        }
    }
}
