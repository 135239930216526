
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Activite } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';
import { ImagesService } from 'src/app/services/images.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-activite',
  templateUrl: './detail-activite.component.html',
  styleUrls: ['./detail-activite.component.scss']
})
export class DetailActiviteComponent implements OnInit {

    activite: Activite;
    activiteContent: string;
    id: number;

    constructor(
        private service: ApiService,
        private route: ActivatedRoute,
        private utils: Utils,
        public imgServ: ImagesService,
    ) { }

    getActivite() {
        if(!this.id)
            this.id = parseInt(this.route.snapshot.paramMap.get('id'));

        this.service.getActiviteById(this.id).subscribe({
            next: res => {
                this.activite = res.data;
                this.activiteContent = this.utils.sanitizeHTML(res.data.attributes.description);
            }
        });

    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.getActivite();
        });

    }

}
