import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Activite, Article, Composant, Formation, Gallery, Logo, Param, Slider } from './models';
import { ApiGetResponse, ApiGetSimpleResponse, Utils } from './utils';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private utils: Utils) { }

  getCollaborateurs(query = '', populate = false): Observable<any>{
    let select = '?';
    if(populate) {
        select += 'populate=*';
    }
    if(query.length) {
        if(select.length > 1) {
            select += '&';
        }
        select += query;
    }
    return this.http.get<any>(`${environment.apiUrl}/collaborateurs${select}`);
  }
  getCollaborateurById(id): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}/collaborateurs/${id}?populate=*`);
  }

  getComposants(page: string = 'accueil', section: string = undefined): Observable<ApiGetResponse<Composant>> {
    let selSection = `populate=*&filters[page][$containsi]=${page}`;
    if(section)
        selSection += `&filters[section][$containsi]=${section}`;

    return this.http.get<ApiGetResponse<Composant>>(`${environment.apiUrl}/composants?${selSection}`);
  }

  getActivites(lieu: string = '', idSuppr: number = null): Observable<ApiGetResponse<Activite>> {
    let sel = 'populate=*';
    if(lieu)
        sel += `&filters[${lieu}][$eq]=true`;
    if(idSuppr)
        sel += `&filters[id][$ne]=${idSuppr}`;

    return this.http.get<ApiGetResponse<Activite>>(`${environment.apiUrl}/activites?${sel}`);
  }

  getActiviteById(id: number): Observable<ApiGetSimpleResponse<Activite>> {
    const sel = 'populate=*';//&filters[id][$eq]=${id}`;
    return this.http.get<ApiGetSimpleResponse<Activite>>(`${environment.apiUrl}/activites/${id}?${sel}`);
  }

  getActivitesName(): Observable<ApiGetResponse<Activite>> {
    const sel = `fields[0]=nom&fields[1]=urlSpecifique`;
    return this.http.get<ApiGetResponse<Activite>>(`${environment.apiUrl}/activites?${sel}`);
  }


  getParam(nom: string = ''): Observable<ApiGetResponse<Param>> {
    let sel = '';
    if(nom)
        sel = `filters[nom][$eq]=${nom}`;
    return this.http.get<ApiGetResponse<Param>>(`${environment.apiUrl}/parametres?${sel}`);
  }
  getParamValue(paramName: string, params: Array<Param>, sanitize: boolean = false, formatTel: boolean = false) {
    let item: Param = params.filter(elem => elem?.attributes?.nom == paramName)[0];
    if(formatTel)
        item.attributes.valeur = `(+33) ${item?.attributes?.valeur.substring(1)}`;
    if(sanitize)
        return this.utils.sanitizeHTML(item?.attributes?.valeur);
    else
        return item?.attributes?.valeur;
}

  getSlider(identifiant: string): Observable<ApiGetResponse<Slider>> {
    const sel = `populate=*&sort[0]=ordre&filters[identifiant][$eq]=${identifiant}`;
    return this.http.get<ApiGetResponse<Slider>>(`${environment.apiUrl}/sliders?${sel}`);
  }

  getGallerie(): Observable<ApiGetResponse<Gallery>> {
    return this.http.get<ApiGetResponse<Gallery>>(`${environment.apiUrl}/galeries?populate=*`);
  }

//   getCreneaux(idActivite: Array<number> | number): Observable<ApiGetResponse<Creneau>> {
//     return this.http.get<ApiGetResponse<Creneau>>
//   }

  getArticles(): Observable<ApiGetResponse<Article>> {
    return this.http.get<ApiGetResponse<Article>>(`${environment.apiUrl}/articles?populate=*`);
  }

  getFormations(): Observable<ApiGetResponse<Formation>> {
    return this.http.get<ApiGetResponse<Formation>>(`${environment.apiUrl}/formations?populate=*`);
  }
  getFormationById(id: number): Observable<ApiGetSimpleResponse<Formation>> {
    return this.http.get<ApiGetSimpleResponse<Formation>>(`${environment.apiUrl}/formations/${id}?populate=*`);
  }
  getLogo(): Observable<ApiGetSimpleResponse<Logo>> {
    return this.http.get<ApiGetSimpleResponse<Logo>>(`${environment.apiUrl}/logo?populate=*`);
  }
}
