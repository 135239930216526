

<div class="inner-banner bg-shape3 bg-color5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Shop</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Shop</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-area pt-94 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <h2>Our All Latest Diving & Swimming Equipments</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/1.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Diving Glass</h3></a>
                            <span>$35</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/2.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Diving Rite</h3></a>
                            <span>$30</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/3.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Fins Bungee</h3></a>
                            <span>$75</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/4.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Life Jacket</h3></a>
                            <span>$25</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/5.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Diving Masks</h3></a>
                            <span>$35</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/6.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Swimming Fins</h3></a>
                            <span>$50</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/7.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Gas Masks</h3></a>
                            <span>$75</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/8.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Antifog Masks</h3></a>
                            <span>$25</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/9.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Flippers</h3></a>
                            <span>$25</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/10.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Pool Float</h3></a>
                            <span>$25</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/11.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Snorkel Masks</h3></a>
                            <span>$25</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/12.jpg" alt="Product Images"></a>
                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"><h3>Mesh Sack</h3></a>
                            <span>$25</span>
                        </div>
                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a href="#" class="prev page-numbers"><i class="fas fa-chevron-left"></i></a>
                    <a href="#" class="page-numbers current" aria-current="page">1</a>
                    <a href="#" class="page-numbers ">2</a>
                    <a href="#" class="page-numbers">3</a>
                    <a href="#" class="next page-numbers"><i class="fas fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
