
<app-banner [title]="'Présentation du CNN'"
            [breadcrumb]="[['Accueil', 'index.html'], ['activités', '/'], [activite?.attributes?.nom , 'activite/'+activite?.id]]"
            [bckUrl]="''"
></app-banner>

<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-white">
    <div aria-hidden="true" class="relative">
      <img [src]="imgServ.imageUrl(activite?.attributes?.photo, '1000x385')" alt="" class="h-96 w-full object-cover object-center">
      <div class="absolute inset-0 bg-gradient-to-t from-white"></div>
    </div>

    <div class="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
      <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{activite?.attributes?.nom}}</h2>
        <div class="para mt-4 text-gray-500" [innerHTML]="activiteContent"></div>
      </div>


    </div>
    <div class="about-more-btn flex justify-center">
        <a [routerLink]="'/private/adhesion/add/'+activite?.id" class="default-btn4">s'inscrire</a>
    </div>
</div>
<section class="trainers-area pt-100 pb-70">
    <app-activites [idSuppr]="id" ></app-activites>
</section>

<section class="trainers-area pt-100 pb-70">
    <app-trainers></app-trainers>
</section>
