<app-banner [title]="'Le CNN au Palais du travail (Narbonne)'"
            [breadcrumb]="[['Accueil', 'index.html'], ['Présentation palais du travail', 'about']]"
            [bckUrl]="'about'"
></app-banner>
<div class="about-detl pt-100 pb-70">
    <div class="container">
        <div class="common-dtel-img">
            <img src="assets/img/about/palais-des-sports-des-arts-travail-narbonne-large.webp" alt="About Images">
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-text">
                    <h2>{{aPropos?.attributes.titre}}</h2>
                    <div [innerHTML]="aProposContent"></div>
                    </div>
            </div>

            <div class="col-lg-4">
                <div class="common-offer">
                    <div class="drive-img">
                        <img src="assets/img/about/nager.webp" alt="nager à Narbonne">
                    </div>

                    <div class="common-offer-text">
                        <h3>Venez apprendre &agrave; nager et plus</h3>
                        <a href="tel:+33468320656" class="call-btn">Appeler</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<section class="product-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Products </span>
            <h2>Buy the Equipment You Need</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/1.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Glass</h3></a>
                            <span>$35</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/2.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Rite</h3></a>
                            <span>$30</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/3.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Fins Bungee</h3></a>
                            <span>$75</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/4.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Life Jacket</h3></a>
                            <span>$25</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
<section class="trainers-area pt-100 pb-70">
    <app-activites [lieu]="'palaisTravail'"></app-activites>
 </section>

<section class="trainers-area pt-100 pb-70">
   <app-trainers></app-trainers>
</section>
