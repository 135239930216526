import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ImagesService } from 'src/app/services/images.service';
import { Article } from 'src/app/services/models';

@Component({
  selector: 'app-liste-news',
  templateUrl: './liste-news.component.html',
  styleUrls: ['./liste-news.component.scss']
})
export class ListeNewsComponent implements OnInit {

    news: Article[] = [];

    @Input() affPagination = false;

    constructor(private service: ApiService, public imgServ: ImagesService) { }

    ngOnInit(): void {
        this.service.getArticles().subscribe({
            next: art => this.news = art.data,
            error: err => console.log(err)
        });
    }

}
