<app-banner [title]="'S\'inscrire'"
            [breadcrumb]="[['Accueil', 'index.html'],['tableau de bord', 'private'], ['inscription', 'login/register']]"
            [bckUrl]="'login'"
></app-banner>

<div class="register-area ptb-100" >
    <div class="container pt-8 pb-8" id="frise">
        <nav aria-label="Progress" class="flex justify-center">
            <ol role="list" class="flex items-center">
                <li [ngClass]="(item < nbSteps.length) ? 'relative pr-8 sm:pr-20':'relative'" *ngFor="let item of nbSteps">

                    <!-- Completed Step -->
                    <div class="absolute inset-0 flex items-center" aria-hidden="true" *ngIf="item < currentStep">
                        <div class="h-0.5 w-full bg-[#373e58]"></div>
                    </div>
                    <button *ngIf="item < currentStep" (click)="returnStep(item)" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-[#373e58] hover:bg-indigo-900">
                        <!-- Heroicon name: mini/check -->
                        <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Vos informations</span>
                    </button>


                    <!-- Current Step -->
                    <div class="absolute inset-0 flex items-center" aria-hidden="true" *ngIf="item === currentStep">
                        <div class="h-0.5 w-full bg-gray-200"></div>
                    </div>
                    <a *ngIf="item === currentStep"  class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-[#373e58] bg-white" aria-current="step">
                        <span class="h-2.5 w-2.5 rounded-full bg-[#373e58]" aria-hidden="true"></span>
                        <span class="sr-only">votre adhésion</span>
                    </a>

                    <!-- Upcoming Step -->
                    <div class="absolute inset-0 flex items-center" aria-hidden="true" *ngIf="item > currentStep">
                        <div class="h-0.5 w-full bg-gray-200"></div>
                    </div>
                    <a *ngIf="item > currentStep"  class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                        <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true"></span>
                        <span class="sr-only">Confirmation</span>
                    </a>



                </li>
            </ol>
        </nav>
    </div>
    <div class="container">
        <div class="register-form" *ngIf="currentStep===1">
            <h3>Vos informations</h3>
            <div class="form-sign">
                <form [formGroup]="adherentForm" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="nom">Votre nom</label>
                                <input type="text" formControlName="nom" id="nom" class="form-control" placeholder="Nom">
                            </div>
                            <div *ngIf="nom?.errors && (nom?.errors?.['required'] === true) && (nom?.dirty || nom?.touched)" class="alert alert-danger">
                                votre nom est requit
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="prenom">Votre prénom</label>
                                <input type="text" formControlName="prenom" id="prenom" class="form-control" placeholder="Prénom">
                            </div>
                            <div *ngIf="prenom?.errors && (prenom?.errors['required'] === true) && (prenom?.dirty || prenom?.touched)" class="alert alert-danger">
                                votre pr&eacute;nom est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="adresse">Votre Adresse</label>
                                <input type="text" formControlName="adresse" id="adresse" class="form-control" placeholder="Adresse">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="adress_suite">&nbsp;</label>
                                <input type="text" formControlName="adress_suite" id="adress_suite" class="form-control" placeholder="Complément d'adresse">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <div *ngIf="!modifVille">
                                    <span>{{villeSelected?.attributes?.code_postal}}&nbsp;{{villeSelected?.attributes?.nom_commune}} </span>
                                    <button (click)="modifVille = !modifVille" type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Modifier</button>
                                </div>
                                <app-ville (ville)="villeSelected = $event" *ngIf="modifVille"></app-ville>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="telephone">Votre téléphone</label>
                                <input type="text" formControlName="telephone" id="telephone" class="form-control" placeholder="Téléphone fixe">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="telMobile">Votre téléphone mobile</label>
                                <input type="text" formControlName="telMobile" id="telMobile" class="form-control" placeholder="Téléphone mobile">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="email">Votre e-mail</label>
                                <input type="text" formControlName="email" id="email" class="form-control" placeholder="E-mail">
                            </div>
                            <div *ngIf="email?.errors && (email?.errors?.['required'] === true) && (email?.dirty || email?.touched)" class="alert alert-danger">
                                votre adresse e-mail est requise
                            </div>
                            <div *ngIf="email?.errors && (email?.errors?.['email'] === true) && (email?.dirty || email?.touched)" class="alert alert-danger">
                                votre adresse e-mail comporte des erreurs
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label for="dateNaissance">Votre Date de naissance</label>
                                <input type="date" formControlName="dateNaissance" id="dateNaissance" class="form-control" placeholder="Date de naissance">
                            </div>
                        </div>


                    </div>
                </form>
            </div>


        </div>

        <!--Etape 2 Documents-->
        <div class="container">
            <div class="register-form" *ngIf="currentStep===2">
                <div class="md:flex justify-around">
                    <div>
                        <h3>Votre certificat médical</h3><h3 class="italic text-gray-500" style="font-size: 1rem; font-weight: 100;">ou votre attestation</h3>
                        <app-upload
                            [media]="adherent?.attributes?.certificat?.data"
                            (mediaId)="idCertificat = $event"
                            [extension]="'image/png, image/jpeg, .pdf'"
                        ></app-upload>
                    </div>
                    <div>
                        <h3>Votre photo</h3><p>&nbsp;</p>
                        <app-upload
                            [media]="adherent?.attributes?.photo?.data"
                            [phrase]="'Déposer une image (png, jpg)'";
                            (mediaId)="idPhoto = $event" [extension]="'image/png, image/jpeg'"
                        ></app-upload>
                    </div>
                </div>

            </div>
        </div>
        <!--Fin etape 2-->
        <!--Etape 3 resume-->
        <div class="register-form" *ngIf="currentStep>=3">
            <div class="flex justify-center">
                <ul class="bg-white rounded-lg border border-gray-200 w-96 text-gray-900">
                    <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
                        <span class="italic text-gray-600">Nom&nbsp;:&nbsp;</span>{{adherentForm.value.nom}}
                    </li>
                    <li class="px-6 py-2 border-b border-gray-200 w-full">
                        <span class="italic text-gray-600">Pr&eacute;nom&nbsp;:&nbsp;</span>{{adherentForm.value.prenom}}
                    </li>
                    <li class="px-6 py-2 border-b border-gray-200 w-full">
                        <span class="italic text-gray-600">Adresse&nbsp;:&nbsp;</span>{{adherentForm.value.adresse}}
                    </li>
                    <li class="px-6 py-2 border-b border-gray-200 w-full">
                        <span class="italic text-gray-600">Compl&eacute;ment d'adresse&nbsp;:&nbsp;</span>{{adherentForm.value.adress_suite}}
                    </li>
                    <li class="px-6 py-2 border-b border-gray-200 w-full">
                        <span class="italic text-gray-600">Ville&nbsp;:&nbsp;</span>{{villeSelected?.attributes?.code_postal}}&nbsp;{{villeSelected?.attributes?.nom_commune}}
                    </li>
                </ul>
            </div>
        </div>
        <!--Fin etape 3-->
        <div class="col-lg-12 col-md-12 text-center mt-6">
            <button type="submit" [disabled]="valid " (click)="increaseStep()" class="default-btn1 btn-two">{{btnText}}</button>
        </div>
    </div>

</div>

