import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { InstructorComponent } from './components/pages/instructor/instructor.component';
import { CoursesComponent } from './components/pages/courses/courses.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsDetailsComponent } from './components/pages/news-details/news-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PalaisComponent } from './components/pages/about/palais/palais.component';
import { NarbonnePlageComponent } from './components/pages/about/narbonne-plage/narbonne-plage.component';
import { DetailActiviteComponent } from './components/activites/detail-activite/detail-activite.component';
import { DetailCourseComponent } from './components/pages/courses/detail-course/detail-course.component';
import { AuthGuard } from './private/auth/auth.guard';
import { AdherentComponent } from './private/dashboard/adherent/adherent.component';
import { ImpressionComponent } from './admin/adherents/imp-cartes/impression/impression.component';
import { AuthAdminGuard } from './private/auth/authAdmin.guard';
import { LoginComponent } from './private/auth/login/login.component';
import { InscriptionComponent } from './components/inscription/inscription.component';

const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'home-two', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'about/palais-du-travail', component: PalaisComponent},
    {path: 'about/narbonne-plage', component: NarbonnePlageComponent},
    {path: 'activites/:id', component: DetailActiviteComponent},
    {path: 'appointment', component: AppointmentComponent},
    {path: 'instructor', component: InstructorComponent},
    {path: 'instructor/:id', component: InstructorComponent},
    {path: 'courses', component: CoursesComponent},
    {path: 'courses/:id', component: DetailCourseComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'terms-condition', component: TermsConditionComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'private', loadChildren: () => import('./private/private.module').then(m => m.PrivateModule), canActivate: [AuthGuard]},
    {path: 'login', component: LoginComponent},
    {path: 'login/register', component: RegisterComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'news', component: NewsComponent},
    {path: 'news-details', component: NewsDetailsComponent},
    {path: 'inscription', component: InscriptionComponent},
    // {path: 'shop', component: ShopComponent},
    // {path: 'cart', component: CartComponent},
    // {path: 'checkout', component: CheckoutComponent},
    // {path: 'products-details', component: ProductsDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'adherent', component: AdherentComponent, },//canActivate: [AuthGuard] },
    {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthAdminGuard]},
    {path: 'impression-cartes', component: ImpressionComponent, canActivate: [AuthAdminGuard] },
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
