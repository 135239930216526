import { NgModule } from '@angular/core';
import { NavbarStyleTwoComponent } from '../components/common/navbar-style-two/navbar-style-two.component';
import { BannerComponent } from '../components/common/banner/banner.component';
import { ActivitesComponent } from '../components/activites/activites.component';
import { VilleComponent } from '../private/ville/ville.component';
import { UploadComponent } from '../private/upload/upload.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PointsSuspensionComponent } from './points-suspension/points-suspension.component';
import { FindActivitesComponent } from './find-activites/find-activites.component';
import { ImpressionComponent } from '../admin/adherents/imp-cartes/impression/impression.component';


@NgModule({
  declarations: [
    NavbarStyleTwoComponent,
    BannerComponent,
    ActivitesComponent,
    VilleComponent,
    UploadComponent,
    PointsSuspensionComponent,
    FindActivitesComponent,
    ImpressionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    NavbarStyleTwoComponent,
    BannerComponent,
    ActivitesComponent,
    VilleComponent,
    UploadComponent,
    FormsModule,
    ReactiveFormsModule,
    PointsSuspensionComponent,
    FindActivitesComponent,
    ImpressionComponent,
  ]
})
export class SharedModule { }
