import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Ville } from 'src/app/services/models';
import { PrivateService } from '../private.service';

@Component({
  selector: 'app-ville',
  templateUrl: './ville.component.html',
  styleUrls: ['./ville.component.scss']
})
export class VilleComponent implements OnInit {
    villes: Array<Ville> = [];
    affDropdown: boolean = false;
    nomVille = "Sélectionnez votre ville";
    @Output() ville = new EventEmitter();

    constructor(private privServ: PrivateService) { }

    ngOnInit(): void {
    }

    getVilles(evt: any) {
        const cp = evt.target.value;
        if(cp.length === 5)
            this.privServ.getVilles(cp).subscribe({
                next: resVil => this.villes = resVil.data,
              //  complete: () => console.log(this.villes)
            });
    }
    changeVille(evt: number) {
        this.affDropdown = false;
        const v = this.villes.filter(filter => filter.id == evt)[0];
        this.nomVille = v.attributes.nom_commune;
        this.ville.emit(v);
    }

}
