import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminServiceService } from 'src/app/admin/admin-service.service';
import { Adherent, Creneau } from 'src/app/services/models';

@Component({
  selector: 'app-impression',
  templateUrl: './impression.component.html',
  styleUrls: ['./impression.component.scss']
})
export class ImpressionComponent implements OnInit {
    listeAdherents: Adherent[] = [];

    constructor(private adminService: AdminServiceService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.adminService.getCarte().subscribe({
            next: liste => {this.listeAdherents = liste.data.attributes.listeAdherents, console.log(this.listeAdherents)}
        });
    }

    photoUrl(adh: Adherent) {
        return this.adminService.photoProfile(adh)
    }

    genereCreneaux(creneaux: Creneau[]) {
        let listeCreneaux = '';
        if(creneaux?.length) {
            creneaux.forEach(creneau => {
                listeCreneaux += listeCreneaux.length ? ' / ' : ''
                listeCreneaux += `${creneau.attributes.jour} ${creneau.attributes.heureDebut} - ${creneau.attributes.heureFin}`;
            });
        }
        return listeCreneaux;
    }

}
