import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private alert: AlertService
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     /* let needLogout = false;
      const reqHeader = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.authService.token()
      });

      const users = this.http.get<Connect>(`${environment.apiUrl}/users`, {headers: reqHeader});

      if (!this.authService.token()) {
          this.authService.logout();
          return false;
      } else {
        users.subscribe(res => {
          needLogout = false;
        }, error => {
          if (error.status === 401) {
            this.authService.logout();
            return false;
          }
        });
      }
      return true;*/
      let routeMessage: string = null;
    //   const isTokenExpired = this.authService.isTokenExpired();
    //   if (!isTokenExpired) {
      const isLoggedIn = this.authService.isLoggedIn();
      if (isLoggedIn) {
          return true;
      } else {
          routeMessage = "Vous devez vous connectez pour continuer";
      }
    //   } else {
    //   routeMessage = "Your session has expired."
    //   }
      if (routeMessage) this.alert.setMessage({mode: 'warning', titre: 'authentification', texte: routeMessage});

      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

}
