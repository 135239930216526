

<app-banner [title]="'Se Connecter'"
            [breadcrumb]="[['Accueil', 'index.html'], ['connexion', 'login']]"
            [bckUrl]="'login'"
></app-banner>
<div class="pt-50">&nbsp;</div>
<div class="login-area ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <i class="far fa-user text-center"></i>
                <h3>Se connecter</h3>
            </div>

            <div class="form-sign">
                <div class="col-lg-12 col-md-12 text-center mtp-4 alert alert-danger" *ngIf="error">{{error}}</div>
                <form [formGroup]="loginForm" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="identifier">Identifiant</label>
                                <input type="text" formControlName="identifier" id="identifier" class="form-control" required placeholder="adresse e-mail">
                            </div>
                            <div *ngIf="identifier?.errors && (identifier?.errors?.['required'] === true) && (identifier?.dirty || identifier?.touched)" class="alert alert-danger">
                                votre adresse e-mail est requise
                            </div>
                            <div *ngIf="identifier?.errors && (identifier?.errors?.['identifier'] === true) && (identifier?.dirty || identifier?.touched)" class="alert alert-danger">
                                votre adresse e-mail comporte des erreurs
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="password">Mot de passe</label>
                                <input type="password" formControlName="password" id="password" class="form-control" required placeholder="mot de passe">
                            </div>
                            <div *ngIf="password?.invalid && (password?.errors?.['required'] === true) && (password?.dirty || password?.touched)" class="alert alert-danger">
                                votre mot de passe est requit
                            </div>
                            <div *ngIf="password?.errors && (password?.errors?.['minlength']) && (password?.dirty || password?.touched)" class="alert alert-danger">
                                le mot de passe doit comporter au moins 10 caractères
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <!--<div class="form-check form-group">
                                <input type="checkbox" class="form-check-input" id="ExapleCheckbox">
                                <label class="form-check-label" for="ExapleCheckbox">Remember me</label>
                            </div>-->
                        </div>

                        <div class="col-lg-12 col-md-12 text-center">
                            <button type="submit" class="login-btn btn-two" [disabled]="loginForm.pristine || !loginForm.valid">Se connecter</button>
                        </div>
                        <div class="text-sm w-full flex justify-center mt-4">
                            <a routerLink="/forgot" class="font-medium">Mot de passe oublié?</a>
                          </div>
                    </div>
                </form>
            </div>

            <p>Vous n'avez pas encore de compte ? <a routerLink="/login/register">S'enregistrer</a></p>
        </div>
    </div>
</div>
