

<div class="inner-banner bg-shape3 bg-color3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Register Now</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Register Now</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="register-area ptb-100">
    <div class="container">
        <div class="register-form">
            <h3>Register Now</h3>
            <div class="form-sign">
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" class="form-control" placeholder="First Name">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" class="form-control" placeholder="Last Name">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Your Email</label>
                                <input type="text" class="form-control" placeholder="Your Email">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Your Phone</label>
                                <input type="text" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Your Subject</label>
                                <input type="text" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" class="form-control" placeholder="Password">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Your Message</label>
                                <textarea name="message" class="form-message textarea-hight"  cols="30" rows="5"  placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 text-center">
                            <button type="submit" class="default-btn1 btn-two">Register Now</button>
                        </div>
                    </div>
                </form>
            </div>

            <p>Have already an account? <a routerLink="/login">Log In</a></p>
        </div>
    </div>
</div>
