import { Composant, Gallery } from 'src/app/services/models';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gallerie',
  templateUrl: './gallerie.component.html',
  styleUrls: ['./gallerie.component.scss']
})
export class GallerieComponent implements OnInit {
    @Input() photos: Composant;
    @Input() photosContent: string;

    gallerie: Gallery[] = [];

    constructor(private service: ApiService ) { }

    get envUrl() {
        return environment.url;
    }

    ngOnInit(): void {
      this.service.getGallerie().subscribe({
          next: gal => this.gallerie = gal.data
      });
    }

}
