<footer class="footer-area print:hidden">
    <div class="footer-top pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="footer-content">
                        <div class="footer-conten-title">
                            <a routerLink="/"><img src="assets/img/logo.webp" alt="Logo"></a>
                            <p>Cercle des Nageurs Narbonnais</p>
                        </div>

                        <div class="newsleter-area">
                            <h3>Inscrivez-vous &agrave; la Newsletter</h3>
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Email" name="EMAIL" required autocomplete="off">
                                <button class="subscribe-btn" type="submit">S'incrire</button>
                            </form>
                        </div>

                        <div class="footer-social">
                            <ul>
                                <li><a [href]="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a [href]="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a [href]="insta" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>



                <div class="col-lg-4 col-sm-6">
                    <div class="footer-list ml-50">
                        <h3>Liens rapides</h3>

                        <ul>
                            <li><a routerLink="/about">Pr&eacute;sentation</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                            <li><a routerLink="/privacy-policy">Mentions l&eacute;gales</a></li>
                            <!--<li><a routerLink="/terms-condition">Conditions g&eacute;n&eacute;rales de vente</a></li>-->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="footer-list">
                        <h3>Contacts</h3>

                        <ul>
                            <li>
                                <a href="tel:+33468320656">
                                    <div class="d-flex flex-row align-items-center">
                                        <div><i class="fas fa-mobile-alt"></i></div>
                                        <div [innerHTML]="tel"></div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="mailto:contact@natation-narbonne.fr">
                                    <div class="d-flex flex-row align-items-center">
                                        <div><i class="far fa-envelope"></i> </div>
                                        <div>contact&#64;natation-narbonne.fr</div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div class="d-flex flex-row align-items-start">
                                    <div><i class="fas fa-map-marker-alt"></i></div>
                                    <div>Bd Frédérique Mistral,<br />11100 NARBONNE</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <!--<div class="bottom-list">
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li><a routerLink="/gallery">Gallery</a></li>
                            <li><a routerLink="/news">News</a></li>
                            <li><a routerLink="/shop">Shop</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>-->
                </div>
                <div class="col-lg-5">
                    <div class="bottom-text">
                        <p>©2022 <strong>Cercle des Nageurs Narbonnais</strong>. Tout droits r&eacute;serv&eacute;s </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
