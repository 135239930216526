<app-banner [title]="'Nos Formations'"
            [breadcrumb]="[['Accueil', 'index.html'], ['Formations', 'courses']]"
            [bckUrl]="'courses'"
></app-banner>
<div class="container text-center mt-4 flex justify-center">
    <img class="w-96 rounded" src="assets/img/course/Logo-Vierge-FNMNS.webp" />
</div>
<div class="course-area pt-100 pb-70">
    <div [class]="'container'">
        <div class="scetion-title text-center mb-50">
            <span>Nos Formations</span>
            <h2>Nous vous proposons les formations suivantes</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let formation of listFormations | async">
                <div class="courses-card-item">
                    <div class="courses-card-img">
                        <a class="cursor-pointer" [routerLink]="'/courses/'+formation.id"><img [src]="formation.attributes.image.data ? url+formation.attributes.image.data.attributes.url : '/assets/img/course/default.webp'" [alt]="'Formation '+formation.attributes.nom"></a>
                        <div class="courses-card-text">{{formation.attributes.tarif}}€</div>
                    </div>
                    <ul>
                        <li class="active">{{formation.attributes.disponibilite}}</li>
                        <li>{{formation.attributes.duree}}</li>
                    </ul>
                    <div class="courses-card-content">
                        <a class="cursor-pointer" [routerLink]="'/courses/'+formation.id"><h3>{{formation.attributes.nom}}</h3></a>
                        <p>{{formation.attributes.resume}}</p>
                        <div class="flex justify-between">
                            <a class="cursor-pointer" [routerLink]="'/courses/'+formation.id" class="course-book-btn">En savoir plus</a>
                            <a class="cursor-pointer" [routerLink]="'/private/formation/add/'+(formation.id ? formation.id : '')" class="course-book-btn">S'inscrire</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container" *ngIf="affDetail">
        <app-detail-course [formation]="formSelected"></app-detail-course>
    </div> -->
</div>
