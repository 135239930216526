<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Gallerie photos</span>
            <h2>{{photos?.attributes?.titre}}</h2>
            <div [innerHTML]="photosContent"></div>
        </div>

        <div class="gallery-view">
            <div class="row">
                <div class="col-lg-4 col-sm-6" *ngFor="let photo of gallerie">
                    <div class="gallery-item">
                        <a [href]="envUrl + photo.attributes.photo.data?.attributes.formats.medium.url">
                            <img [src]="envUrl + photo.attributes.photo.data?.attributes.formats.medium.url" alt="Gallery Images">
                        </a>

                        <div class="gallery-text">
                            <h3>{{photo.attributes.legende}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
