import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    adresse: string = '';
    email: string = '';
    tel: string = '';
    telValue: string = '';
    facebook: string = '';
    insta: string = '';
    twitter: string = '';

  constructor(private service: ApiService) { }

  ngOnInit(): void {
    this.service.getParam().subscribe({
        next: res => {
            this.adresse = this.service.getParamValue('adresse', res.data, true);
            this.email = this.service.getParamValue('email', res.data, true);
            this.tel = this.service.getParamValue('telephone', res.data, true, true);

            this.telValue = this.service.getParamValue('telephone', res.data)
                            .replace('<BR>','')
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('.', '')
                            .replace('/', '');
            this.facebook = this.service.getParamValue('facebook', res.data);
            this.insta = this.service.getParamValue('instagram', res.data);
            this.twitter = this.service.getParamValue('twitter', res.data);
        }
    });
  }

}
