<div class="container">
    <div class="scetion-title text-center mb-50">
        <span>Ma&icirc;tres nageurs</span>
        <h2 [class]="isHome ? 'color-white':''">Notre &eacute;quipe de ma&icirc;tres nageurs professionnels</h2>
        <p [class]="isHome ? 'color-white':''">Nos maîtres nageurs, par leurs compétences et leurs disponibilité, vous permettent de progresser quelque soit votre niveau.</p>
    </div>

    <div class="row">
        <div class="col-lg-3 col-sm-6" *ngFor="let collaborateur of collaborateurs">
            <div class="trainer-card">
                <a [routerLink]="'/instructor/' + collaborateur.id" (click)="sendIdCollaborateur(collaborateur.id)">
                    <img [src]="imgServ.imageUrl(collaborateur.attributes.photo, '270x270')"
                         [alt]="nomPrenom(collaborateur.attributes.nom, collaborateur.attributes.prenom)"></a>

                <div class="trainer-content">
                    <a [routerLink]="'/instructor/' + collaborateur.id" (click)="sendIdCollaborateur(collaborateur.id)"><h3>{{nomPrenom(collaborateur.attributes.nom, collaborateur.attributes.prenom)}}</h3></a>
                    <span>{{collaborateur.attributes.fonctions?.data[0].attributes.nom}}</span>
                    <!--<div class="social-icon">
                        <ul>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                        </ul>
                    </div>-->
                </div>
            </div>
        </div>


    </div>
</div>
