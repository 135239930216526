import { isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnChanges {

  @Input() title: string = '';
  @Input() breadcrumb: string[] = [];
  @Input() bckUrl: string = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.breadcrumb.currentValue !== changes.breadcrumb.previousValue) {
        this.updateBreadcrumb();
      }
  }

  updateBreadcrumb() {
    if(isPlatformBrowser(this.platformId)) {
        const bc = document.getElementById('breadcrumb');
        bc.innerHTML = '';
        this.breadcrumb.forEach((bread, index) => {
        let li = document.createElement('li');
        let chevron = document.createElement('li');
        let a = document.createElement('a');
        a.setAttribute("href", bread[1]);
        const content = document.createTextNode(bread[0]);

        if (index < (this.breadcrumb.length - 1)) {
            a.appendChild(content);
            li.appendChild(a);
            chevron.innerHTML = '<i class="fas fa-chevron-right"></i>';
            bc.appendChild(li);
            bc.appendChild(chevron);
        } else {
            li.appendChild(content);
            bc.appendChild(li);
        }
        });
    }
  }

  ngOnInit(): void {
    this.updateBreadcrumb();
  }

}
