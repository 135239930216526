import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = this.fb.group({
    identifier: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)],]
  });

  token = '';
  role = '';
  error = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
    ) { }

  ngOnInit(): void {
  }

  get identifier() {
    return this.loginForm.get('identifier');
  }

  get password() {
    return this.loginForm.get('password');
  }

  async submitold() {
    console.log(this.loginForm.value);
    this.authService.login(this.loginForm.value);
    this.token = localStorage.getItem(environment.tokenKey);


    if(this.authService.isLoggedIn)
        this.router.navigateByUrl('/private');
  }

  public submit() {

      this.authService.login(this.loginForm.value).subscribe({
          next: (res) => {
            this.error = '';

            this.token = res.jwt;//sessionStorage.getItem(environment.tokenKey);
            //console.log(this.route.snapshot.params);
            this.route.queryParams.subscribe({
                next: params => {
                    console.log(params)
                    const url = params.returnUrl ? params.returnUrl : '/private';
                    console.log(url);
                    this.router.navigateByUrl(params.returnUrl ? params.returnUrl : '/private');
                }
            })
            //this.router.navigateByUrl(this.route.snapshot.params.returnUrl);
          },
          error: err => { this.error = 'Identifiant ou mot de passe invalide.';
        }
      });
    }



}
