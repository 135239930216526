<app-navbar-style-two></app-navbar-style-two>

<div [class]="'inner-banner bg-shape3 bg-color4 img-bck ' + 'img-bck-' + bckUrl">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>{{title}}</h3>
                    <ul id="breadcrumb">
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>