import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination } from 'src/app/services/utils';

@Component({
  selector: 'app-points-suspension',
  templateUrl: './points-suspension.component.html',
  styleUrls: ['./points-suspension.component.scss']
})
export class PointsSuspensionComponent implements OnInit {

    @Input() debut = 1;
    @Input() fin = 3;
    @Input() pagination: Pagination;
    tabVal: Array<number> = [];
    @Output() clickedNumber: EventEmitter<number> = new EventEmitter();

    ngOnInit(): void {
        let index = 0;
        for (let val = this.debut; val <= this.fin; val++) {
            this.tabVal[index] = val;
            index++;
        }
    }

}
