import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

    constructor() { }
    // size exemple: 270x270, img = object media from api, format = format desired for image render
    imageUrl(img, size = '350x350', format = 'webp') {
        let url = '#';

        if(img?.data) {
        //     return '#';
            url = img.data.attributes.hash  ?
            `${environment.url}/uploads/${img.data.attributes.hash}` : '';
            if(url !== '' && img.data.attributes.ext) {
                url += img.data.attributes.ext;
            } else
                {url = '#';}
            if(url !== '#') {
                url += '?';
                url += format.length ? `format=${format}&` : '';
                url += size.length ? `resize=${size}&` : '';
                url += 'embed';
            }
        }
        //const url = `${img?.data?.attributes?.hash}${img?.data?.attributes?.ext}`;
        return url;
    }
}
