
import { Component, DoCheck, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Adherent, Ville } from 'src/app/services/models';
import { PrivateService } from '../../private.service';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmService } from 'src/app/services/confirm.service';

@Component({
  selector: 'app-adherent',
  templateUrl: './adherent.component.html',
  styleUrls: ['./adherent.component.scss']
})
export class AdherentComponent implements OnInit, DoCheck {
    user: any;
    adherentForm: FormGroup;

    nbSteps = [1,2,3];
    currentStep = 1;
    btnText = 'Etape suivante';
    @Input() adherent: Adherent;
    villes: Array<Ville> = [];
    villeSelected: Ville;

    idCertificat: number = null;
    idPhoto: number = null;
    modifVille = true;
    return = '';
    retour = 0;
    adhTrouve: Adherent;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private privServ: PrivateService,
        private alert: AlertService,
        private confirm: ConfirmService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if(isPlatformBrowser(this.platformId)) {
            this.user = JSON.parse(sessionStorage.getItem('user'));
            this.adherentForm = this.fb.group({
                nom: ['', Validators.required],
                prenom: ['', Validators.required],
                adresse: [''],
                adress_suite: [''],
                telephone: [''],
                telMobile: [''],
                email: [this.user.email, [Validators.required, Validators.email]],
                dateNaissance: [null],
                ville: [null],
                user: [this.user.id],
                livretFamille: [null],
                adhesions:[null],
                certificat: [null],
                photo: [null]
            });
        }
    }

    get email() {
        return this.adherentForm.get('email');
    }

    get prenom() {
        return this.adherentForm.get('prenom');
    }

    get nom() {
        return this.adherentForm.get('nom');
    }

    ngOnInit(): void {
        this.getParamUrl();
        if(this.route.snapshot.params.id) {
            this.privServ.getAdherentById(this.route.snapshot.params.id).subscribe({
                next: resAd => {
                    this.adherent = resAd.data;
                    const tabAdhesions: Array<number> = [];
                    this.adherent?.attributes?.adhesions?.data?.forEach(element => {
                        tabAdhesions.push[element.id];
                    });
                    this.adherentForm.setValue({
                        nom: this.adherent.attributes.nom,
                        prenom: this.adherent.attributes.prenom,
                        adresse: this.adherent.attributes.adresse,
                        adress_suite: this.adherent.attributes.adress_suite,
                        telephone: this.adherent.attributes.telephone,
                        telMobile: this.adherent.attributes.telMobile,
                        email: this.adherent.attributes.email,
                        dateNaissance: this.adherent.attributes.dateNaissance,
                        ville: this.adherent.attributes.ville.data.id,
                        user: this.adherent.attributes.user.data.id,
                        livretFamille: null,
                        adhesions: tabAdhesions,
                        certificat: this.adherent.attributes.certificat,
                        photo: this.adherent.attributes.photo
                    });
                    this.villeSelected = this.adherent.attributes.ville.data;
                    if(this.villeSelected)
                        this.modifVille = false;
                    this.idCertificat = this.adherent.attributes?.certificat?.data?.id;
                    this.idPhoto = this.adherent.attributes?.photo?.data?.id;
                }
            });
        }
        this.confirm.returnedValue$.subscribe({next: val => this.retour = val})
    }

    ngDoCheck(): void {
        if(this.retour > 0) {
            this.adherentForm.patchValue({
                user: this.user.id,
                nom: this.adhTrouve.attributes.nom,
                prenom: this.adhTrouve.attributes.prenom,
                adresse: this.adhTrouve.attributes.adresse,
                adress_suite: this.adhTrouve.attributes.adress_suite,
                telephone: this.adhTrouve.attributes.telephone,
                telMobile: this.adhTrouve.attributes.telMobile,
                email: this.adhTrouve.attributes.email,
                dateNaissance: this.adhTrouve.attributes.dateNaissance,
                ville: this.adhTrouve.attributes.ville.data.id,
                livretFamille: null,
                adhesions: this.adhTrouve.attributes.adhesions,
                certificat: this.adhTrouve.attributes.certificat,
                photo: this.adhTrouve.attributes.photo
            });
        } else if(this.retour === 0)
            this.currentStep = 1;
    }

    get valid() {
        switch (this.currentStep) {
            case 1:
                if((!this.adherentForm.valid))
                    return true;
                else
                    return false;
                break;
            case 2:
                return false;
                break;
            case 3:
                return false;
                break;
            default:
                break;
        }
    }

    // changeVille(evt: number) {
    //    this.villeSelected = this.villes.filter(filter => filter.id == evt)[0];
    // }
    returnStep(item) {
        this.currentStep = item;
        this.btnText = 'Etape suivante';
    }

    adherentExistVerif() {
        this.privServ.findAdherent(this.adherentForm.value.nom, this.adherentForm.value.prenom, '', this.adherentForm.value.dateNaissance).subscribe({
            next: res => {
                console.log(res);
                if(res.data.length) {
                    if(res.data[0].attributes.user.data.attributes.username === 'CNN') {
                        this.adhTrouve = res.data[0];
                        this.confirm.setMessage({
                            mode: 'warning',
                            titre: 'Adhérent existant',
                            texte: 'Un adhérent avec les mêmes nom, prénom et date de naissance existe déjà<br />Voulez-vous le rattacher à votre compte ?',
                            btnAnnule: "Annuler",
                            btnValide: "Rattacher",
                            value: res.data[0].id
                        });

                    }
                }
            }
        });
    }

    increaseStep() {
        switch (this.currentStep) {
            case 1:
                this.adherentForm.patchValue({
                    ville: this.villeSelected?.id
                });
                this.adherentExistVerif();
                break;
            case 2:
                this.adherentForm.patchValue({
                    certificat: this.idCertificat,
                    photo: this.idPhoto
                });
                break;
            case 3:
                this.submit();
                break;
            default:
                break;
        }
        this.currentStep++;
        const nbS = this.nbSteps[this.nbSteps.length -1];
        if(this.currentStep === nbS) {
            this.btnText = "Enregistrer";
        }
        if(this.currentStep > nbS) {
            //console.log(this.adherentForm.value);
        }
    }

    // getVilles(evt: any) {
    //     const cp = evt.target.value;
    //     if(cp.length === 5)
    //         this.privServ.getVilles(cp).subscribe({
    //             next: resVil => this.villes = resVil.data,
    //             complete: () => console.log(this.villes)
    //         });
    // }

    getParamUrl() {
        this.route.queryParams.subscribe( params => {
            this.return = params['return'];
        });
    }

    returnTo(id: number) {
        let nav = '/private';
        let query: any = {};
        if(this.return?.length) {
            nav = '/private/adhesion/add/'+this.return;
            query = { queryParams: {idAdh: id}};
        }
        this.privServ.getAdherentById(id).subscribe({
            next: resGetUpd => {
                this.adherent = resGetUpd.data;
                this.router.navigate([nav], query);
            }
        });
    }

    submit() {
        let adh: Adherent;
        this.privServ.findAdherent(this.adherentForm.value.nom, this.adherentForm.value.prenom, '', this.adherentForm.value.dateNaissance)
            .subscribe({
                next: resFind => {
                    if(resFind.meta.pagination.total) {
                       this.privServ.updateAdherent(resFind.data[0].id, this.adherentForm.value).subscribe({
                            next: resUpd => this.returnTo(resUpd.data.id)
                       });
                    } else {
                        this.privServ.addAdherent(this.adherentForm.value).subscribe({
                            next: resAdd => this.returnTo(resAdd.data.id)
                        });
                    }
                }
            });
    }

}
