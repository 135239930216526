import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/private/auth/authentication.service';
import { ApiService } from 'src/app/services/api.service';
import { ImagesService } from 'src/app/services/images.service';
import { Composant, Slider } from 'src/app/services/models';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {



  header: Composant;

    headerContent: string;
    aPropos: Composant;
    aproposContent: string;
    photos: Composant;
    photosContent: string;
    avis: Composant;
    avisContent: string;
    equipe: Composant;
    equipeContent: string;
    actualites: Composant;
    actualitesContent: string;
    isSlider: string = 'slider';

    role: string = '';

    slider: Array<Slider> = [];
    sliderImg: Array<string> = ['', '', ''];

    constructor(
        private service: ApiService,
        private utils: Utils,
        private authService: AuthenticationService,
        public imgServ: ImagesService
    ) {
        this.service.getSlider('accueilHeader').subscribe({
            next: resSlide => {
                this.slider = resSlide.data;
                this.slider.forEach((slide, index) => {
                    slide.attributes.description = this.utils.sanitizeHTML(slide.attributes.description);
                    this.sliderImg[index] = imgServ.imageUrl(slide.attributes.illustration, '1920x850');
                });
            }
        });


    }

    ngOnInit(): void {


        this.service.getParam('typeHeader').subscribe({
            next: resSlide => {
                this.isSlider = resSlide.data[0].attributes.valeur;

            }
        });
        this.service.getComposants('accueil', 'header').subscribe({
            next: res => {
                this.header = res.data[0];
                this.headerContent = this.utils.sanitizeHTML(res.data[0].attributes.contenu);
            },
            error: err => console.log(err)
        });
        this.service.getComposants('accueil', 'a propos').subscribe({
            next: resProp => {
                this.aPropos = resProp.data[0];
                this.aproposContent = this.utils.sanitizeHTML(resProp.data[0].attributes.contenu);
            },
            error: err => console.log(err)
        });
        this.service.getComposants('accueil', 'photos').subscribe({
            next: resPho => {
                this.photos = resPho.data[0];
                this.photosContent = this.utils.sanitizeHTML(resPho.data[0].attributes.contenu);
            },
            error: err => console.log(err)
        });
        this.service.getComposants('accueil', 'avis').subscribe({
            next: resAvis => {
                this.avis = resAvis.data[0];
                this.avisContent = this.utils.sanitizeHTML(resAvis.data[0].attributes.contenu);
            },
            error: err => console.log(err)
        });
        this.service.getComposants('accueil', 'equipe').subscribe({
            next: resEqu => {
                this.equipe = resEqu.data[0];
                this.equipeContent = this.utils.sanitizeHTML(resEqu.data[0].attributes.contenu);
            },
            error: err => console.log(err)
        });
        this.service.getComposants('accueil', 'actualites').subscribe({
            next: resActu => {
                this.actualites = resActu.data[0];
                this.actualitesContent = this.utils.sanitizeHTML(resActu?.data[0]?.attributes?.contenu);
            },
            error: err => console.log(err)
        });


        this.authService.role.subscribe(res => this.role = res);
    }



}
